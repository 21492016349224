import {Route, Routes} from "react-router-dom";
import { CardLogin } from "./login/component/CardLogin";
import {Login} from "./login/Login";
import {CardForgotPassword} from "./login/component/CardForgotPassword";
import {CardResetPassword} from "./login/component/CardResetPassword";

export const AuthPage = () => {

    return (
        <Routes>
            <Route element={<Login/>}>
                <Route path={'login'} element={<CardLogin/>}></Route>
                <Route path={'forgot-password'} element={<CardForgotPassword/>}></Route>
                <Route path={'reset-password'} element={<CardResetPassword/>}></Route>
                <Route index element={<CardLogin/>}/>
            </Route>
        </Routes>
    );
};