import {ThemeProvider} from "@mui/material";
import React from "react";
import {theme} from "./_fri/themes/theme";
import {Outlet} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {siteKey} from "./_fri/utils/enum";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

function App() {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="App">
                        <Outlet/>
                    </div>
                </LocalizationProvider>
            </ThemeProvider>
        </GoogleReCaptchaProvider>
    );
}

export default App;
