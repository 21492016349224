import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {getHeader} from "../../../_fri/utils/function";
import {
    ClosePendingOperatorRequest,
    ClosePendingOperatorResponse,
    ClosingCloseAdminRequest,
    ClosingCloseAdminResponse,
    ClosingCloseOperatorRequest,
    ClosingCloseOperatorResponse,
    ClosingPendingAdminRequest,
    ClosingPendingAdminResponse,
    ClosingData,
    ClosingHistoryRequest,
    ClosingHistoryResponse,
} from "../interface/closing";
import {clean, setHistory} from "../../slice/closingSlice";

export const closing = createApi({
    reducerPath: "closing",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/business/closing/`,
        prepareHeaders: (headers, {getState}) => {
            return getHeader(headers, getState);
        },
    }),
    tagTypes:['closing'],
    endpoints: (builder) => ({
        closingHistory: builder.query<ClosingHistoryResponse, ClosingHistoryRequest>({
            query: (data) => ({
                url: `v2/history`,
                method: "Post",
                body: data,
            }),
            providesTags:["closing"],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch((e) => ({error: e}));
                if ("error" in result) {
                    return;
                }

                const data: ClosingData[] = [];

                if (result.data.responseContent.data !== null && result.data.responseContent.data !== undefined) {

                    result.data.responseContent.data.map((item) => {
                        if ("closings" in item) {
                            item.closings.map((closing) => {
                                data.push({
                                    ...closing,
                                    branchUserName: item.user.username,
                                    branchName: item.user.name,
                                });
                            });
                        } else {
                            data.push({...item});
                        }
                    });
                }

                dispatch(
                    setHistory({
                        dataHistory: data,
                        totalPages: result.data.responseContent.pageProperties.totalPages,
                    }),
                );
            },
        }),

        closingPendingAdmin: builder.mutation<ClosingPendingAdminResponse, ClosingPendingAdminRequest>({
            query: (data) => ({
                url: `v1/admin/pending/overview`,
                method: "Post",
                body: data,
            }),
        }),
        closingPendingOperator: builder.mutation<ClosePendingOperatorResponse, ClosePendingOperatorRequest>({
            query: (data) => ({
                url: `v1/pending/overview`,
                method: "Post",
                body: data,
            }),
        }),
        closingCloseAdmin: builder.mutation<ClosingCloseAdminResponse, ClosingCloseAdminRequest>({
            query: (data) => ({
                url: `v1/admin/close`,
                method: "Post",
                body: data,
            }),
            invalidatesTags:['closing'],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch((e) => ({error: e}));
                if ("error" in result) {
                    return;
                }

                dispatch(clean());
            },
        }),
        closingCloseAllAdmin: builder.mutation<ClosingCloseAdminResponse, ClosingCloseAdminRequest>({
            query: (data) => ({
                url: `v1/admin/close/all`,
                method: "Post",
                body: data,
            }),
            invalidatesTags:['closing'],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch((e) => ({error: e}));
                if ("error" in result) {
                    return;
                }

                dispatch(clean());
            },
        }),
        closingCloseOperator: builder.mutation<ClosingCloseOperatorResponse, ClosingCloseOperatorRequest>({
            query: (data) => ({
                url: `v1/close`,
                method: "Post",
                body: data,
            }),
            invalidatesTags:['closing'],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch((e) => ({error: e}));
                if ("error" in result) {
                    return;
                }

                dispatch(clean());
            },
        }),
    }),
});

export const {
    useClosingPendingAdminMutation,
    useClosingPendingOperatorMutation,
    useClosingCloseAdminMutation,
    useClosingCloseAllAdminMutation,
    useClosingCloseOperatorMutation,
    useClosingHistoryQuery,
} = closing;