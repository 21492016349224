import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface Message{
    title?:string
    message:string
}

export interface MessageState{
    successMessage:string | undefined
    errorMessage:string | undefined
    title: string| undefined

}

const initialState:MessageState={
    successMessage:undefined,
    errorMessage: undefined,
    title: undefined
}

export const messageSlice =createSlice({
    name:'userSessionRedux',
    initialState,
    reducers:{
        setSuccessMessage:(state, action:PayloadAction<Message>)=>{
            state.errorMessage=undefined
            state.successMessage=action.payload.message;
            state.title = action.payload.title;
        },
        cleanSuccessMessage:(state)=>{
            state.successMessage=undefined
            state.title = undefined
        },
        setErrorMessage:(state, action:PayloadAction<string>)=>{
            state.successMessage=undefined
            state.title= undefined
            state.errorMessage=action.payload;
        },
        cleanErrorMessage:(state)=>{
            state.errorMessage=undefined
        },
        cleanMessage:(state)=>{
            state.successMessage=undefined
            state.title=undefined
            state.errorMessage=undefined
        }
    }
})

export const {setSuccessMessage,cleanSuccessMessage, cleanErrorMessage, setErrorMessage, cleanMessage} = messageSlice.actions;
export default  messageSlice.reducer;