import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {ForgotPassword, schemaForgotPassword} from "../core/_model";
import {yupResolver} from "@hookform/resolvers/yup";
import {ForgotPasswordRequest} from "../../../../store/api/interface/passwordRecovery";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {useForgotPasswordMutation} from "../../../../store/api/service/passwordRecovery";
import {TextSpinner} from "../../../../_fri/components/CustomTextSpinner";
import {useEffect} from "react";
import {useCleanMessageHook} from "../../../../_fri/hooks/useCleanMessageHook";
import {CardHeader} from "./CardHeader";
import {ButtonCard, SubTextWrap, TitleCardLoginWrap} from "./CardLogin.styles";
import {CustomInput} from "../../../../_fri/components/CustomInput";
import CustomAlertDanger from "../../../../_fri/components/CustomAlert/CustomAlertDanger";

export const CardForgotPassword = () => {
    const navigate = useNavigate();
    const information = useSelector((state: RootState) => state.currentUser.information);
    const [forgotPassword, forgotPasswordStatus] = useForgotPasswordMutation();
    const errorMessage = useSelector((state: RootState) => state.message.errorMessage);
    const cleanMessage = useCleanMessageHook();

    const formForgotPassword = useForm<ForgotPassword>({
        resolver: yupResolver(schemaForgotPassword)
    });

    const onSubmit: SubmitHandler<ForgotPassword> = (value) => {

        const data: ForgotPasswordRequest = {
            info: information,
            requestContent: {
                username: value.username
            }
        };
        forgotPassword(data);
    };

    useEffect(() => {
        if (!forgotPasswordStatus.isSuccess) return;

        navigate("/auth/reset-password", {state: {user: formForgotPassword.getValues("username")}});

    }, [forgotPasswordStatus.isSuccess]);

    return (
        <Box>
            <CardHeader/>
            <TitleCardLoginWrap sx={{paddingBottom: "15px"}}>
                    ¿Olvidaste tu contraseña?
            </TitleCardLoginWrap>

            <form onSubmit={formForgotPassword.handleSubmit(onSubmit)}>
                <SubTextWrap>
                    Para recuperar tu contraseña te enviaremos
                    un código a tu correo electronico
                </SubTextWrap>

                <Box sx={{marginTop: "13px", marginBottom: "16px"}}>
                    <CustomInput label="Usuario"
                                 variant="filled"
                                 InputProps={{disableUnderline: true}}
                                 {...formForgotPassword.register("username")}
                    />
                </Box>

                <CustomAlertDanger text={errorMessage || ""} show={errorMessage !== undefined}/>

                <Box sx={{marginTop: "10px"}}>
                    <ButtonCard
                        variant="contained"
                        type="submit"
                        disabled={forgotPasswordStatus.isLoading || (!formForgotPassword.formState.isValid)}
                    >
                        <TextSpinner isLoading={forgotPasswordStatus.isLoading} text={"Siguiente"}
                                     textSpinner={"Cargando"}/>
                    </ButtonCard>
                </Box>
            </form>
        </Box>
    );
};