import useRolHook from "../../hooks/useRolHook";
import {Rol} from "../../utils/enum";
import {CustomWallet} from "./CustomWallet";
import useInformationHook from "../../hooks/useInformationHook";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {useBalanceAdminQuery} from "../../../store/api/service/transactionQueryAdmin";
import {useBalanceOperatorQuery} from "../../../store/api/service/transactionQueryOperators";

const CustomWalletDrawer = () => {

    const rol = useRolHook();
    const information = useInformationHook();
    const {data: dataAdmin} = useBalanceAdminQuery({info: information}, {
        skip: rol === Rol.OPERATOR,
    });

    const {data: dataOperator} = useBalanceOperatorQuery({info: information}, {
        skip: rol === Rol.ADMIN,
    });

    const {money} = useSelector((state: RootState) => state.moneyWallet);


    return (

        <CustomWallet money={money}/>

    );

};

export default CustomWalletDrawer;