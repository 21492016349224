import {Drawer} from "./DrawerDesktop.styles";
import {DrawerContainer} from "../components/DrawerContainer";
import {RootState} from "../../../../../store/store";
import { useSelector } from 'react-redux'

const DrawerDesktop = () => {
    const open = useSelector((state: RootState) => state.drawer.open)
    return (
        <Drawer variant="permanent" open={open}>
            <DrawerContainer />
        </Drawer>
    )

};

export default DrawerDesktop