import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Information} from "../../interfaces/_models";

const useInformationHook = ():Information =>{

    const information = useSelector((state: RootState) => state.currentUser.information);

    return information;

}

export default useInformationHook;