import {styled} from "@mui/material/styles";

export const fontSizeItemBar = "17px";
export const FlexFill = styled("div")(({theme}) => ({
    display: "flex",
    flex: 1
}));

export const FlexFillCenter = styled("div")(({theme}) => ({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
}));

export const FlexFillContentEnd = styled("div")(({theme}) => ({
    display: "flex",
    flex: 1,
    justifyContent: "flex-end"
}));

export const FlexFillContentStart = styled("div")(({theme}) => ({
    display: "flex",
    flex: 1,
    justifyContent: "flex-start"
}));


export const FlexFillColumn = styled("div")(({theme}) => ({
    display: "flex",
    flex: 1,
    flexDirection: "column"
}));

export const FlexFillColumnCenter = styled("div")(({theme}) => ({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    height:"100%"
}));

export const FlexColumnCenter = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height:"100%"
}));



