import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    getUserLocalStorage,
    initializeInformation,
    initializeSessionInformation, setLocalStorage,
} from "../../_fri/utils/function";
import {CurrentUserState, SessionInformation} from "../../interfaces/auth";
import {KeyLocalStorage} from "../../_fri/utils/enum";

const initialState: CurrentUserState = {
    user: JSON.parse(getUserLocalStorage() || JSON.stringify(initializeSessionInformation())),
    information: initializeInformation(),
};

export const currentUserSlice = createSlice({
    name: "currentUser",
    initialState,
    reducers: {
        saveSession: (state, action: PayloadAction<SessionInformation>) => {
            state.user = action.payload;
        },
        setEmailCurrentUser: (state, action: PayloadAction<string>) => {

            if (state?.user?.data?.business.contactEmailAddress === undefined) return;

            state.user.data.business.contactEmailAddress = action.payload;

            setLocalStorage(KeyLocalStorage.USER, JSON.stringify(state.user));
        },
        setPhoneCurrentUser: (state, action: PayloadAction<string>) => {

            if (state?.user?.data?.user.phoneNumber === undefined) return;

            state.user.data.user.phoneNumber = action.payload;

            setLocalStorage(KeyLocalStorage.USER, JSON.stringify(state.user));
        },
        setSearchEnabled: (state) => {

            if (state?.user?.data?.searchEnabled === undefined) return;
            state.user.data.searchEnabled = !state.user.data.searchEnabled;
            setLocalStorage(KeyLocalStorage.USER, JSON.stringify(state.user));

        },

        setClosingType: (state) => {

            if (state?.user?.data?.user.closingType === undefined) return;

            switch (state.user.data.user.closingType) {
                case "automatic": {
                    state.user.data.user.closingType = "manual";
                    setLocalStorage(KeyLocalStorage.USER, JSON.stringify(state.user));
                    return;
                }
                case "manual": {
                    state.user.data.user.closingType = "automatic";
                    setLocalStorage(KeyLocalStorage.USER, JSON.stringify(state.user));
                    return;
                }
            }

        },

        setAvatar:(state,action: PayloadAction<string>)=>{

            if (state?.user?.data?.business.avatar === undefined) return;
            state.user.data.business.avatar = action.payload;
            setLocalStorage(KeyLocalStorage.USER, JSON.stringify(state.user));


        }
    },
});

export const {
    saveSession,
    setEmailCurrentUser,
    setPhoneCurrentUser,
    setSearchEnabled,
    setClosingType,
    setAvatar
} = currentUserSlice.actions;

export default currentUserSlice.reducer;
