import CustomAlertSuccess from "../../../../_fri/components/CustomAlert/CustomAlertSuccess";
import CustomAlertDanger from "../../../../_fri/components/CustomAlert/CustomAlertDanger";
import {Box, Link, Typography} from "@mui/material";
import usePasswordHook from "../../../../_fri/hooks/usePasswordHook";
import {useLocation, useNavigate} from "react-router-dom";
import {EyePassword} from "../../../../_fri/components/CustomEyePassword";
import {useEffect} from "react";
import {
    useForgotPasswordMutation,
    useRecoverPasswordMutation
} from "../../../../store/api/service/passwordRecovery";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {ForgotPasswordRequest, RecoverPasswordRequest} from "../../../../store/api/interface/passwordRecovery";
import {SubmitHandler, useForm} from "react-hook-form";
import {ResetPassword, schemaResetPassword} from "../core/_model";
import {yupResolver} from "@hookform/resolvers/yup";
import {TextSpinner} from "../../../../_fri/components/CustomTextSpinner";
import {cleanMessage, setErrorMessage} from "../../../../store/slice/messageSlice";
import {CardHeader} from "./CardHeader";
import {ButtonCard, SubTextWrap, TitleCardLoginWrap} from "./CardLogin.styles";
import {CustomInput} from "../../../../_fri/components/CustomInput";

export const CardResetPassword = () => {
    const [togglePassword1, showPassword1] = usePasswordHook();
    const [togglePassword2, showPassword2] = usePasswordHook();
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = !location.state ? {user: ""} : location.state as { user: string };
    const [forgotPassword, forgotPasswordStatus] = useForgotPasswordMutation();
    const information = useSelector((state: RootState) => state.currentUser.information);
    const [recoverPassword, recoverPasswordStatus] = useRecoverPasswordMutation();
    const dispatch = useDispatch();
    const successMessage = useSelector((state: RootState) => state.message.successMessage);
    const errorMessage = useSelector((state: RootState) => state.message.errorMessage);

    useEffect(() => {
        if (!location.state) {
            navigate("/auth/login");
        }
        dispatch(cleanMessage());
    }, []);

    useEffect(() => {

        if (!recoverPasswordStatus.isSuccess) return;

        navigate("/auth/login");

    }, [recoverPasswordStatus.isSuccess]);

    //form
    const formResetPassword = useForm<ResetPassword>({
        resolver: yupResolver(schemaResetPassword)
    });

    //submit
    const onSubmit: SubmitHandler<ResetPassword> = (value) => {

        if (value.password1 !== value.password2) {
            dispatch(setErrorMessage("Las contraseñas no coinciden"));
            return;
        }

        const data: RecoverPasswordRequest = {
            info: information,
            requestContent: {
                username: locationState.user,
                verificationCode: value.code,
                password: value.password1
            }
        };

        recoverPassword(data);

    };


    const resendCode = () => { //service resend code

        const data: ForgotPasswordRequest = {
            info: information,
            requestContent: {
                username: locationState.user
            }
        };
        forgotPassword(data);
    };

    return (
        <Box>
            <CardHeader></CardHeader>
            <TitleCardLoginWrap sx={{paddingBottom: "0.813rem"}}>
                Crea una contraseña
            </TitleCardLoginWrap>

            <SubTextWrap>
                Se ha enviado un código al correo registrado en tu usuario <span>{locationState.user}</span>
            </SubTextWrap>

            <form onSubmit={formResetPassword.handleSubmit(onSubmit)}>
                <Box sx={{marginTop: "1.875rem"}}>
                    <CustomInput
                        label="Código de verificación"
                        variant="filled"
                        InputProps={{disableUnderline: true}}
                        {...formResetPassword.register("code")}
                    />
                </Box>
                <Box sx={{fontWeight: 600, cursor: "pointer", fontStyle: "italic", paddingLeft: "0.313rem"}}>
                    <Link underline="hover" component="button" disabled={forgotPasswordStatus.isLoading}
                          onClick={resendCode} type={"button"}>
                        <Typography>
                            {"Reenviar código"}
                        </Typography>
                    </Link>
                </Box>
                <Box sx={{marginTop: "1.438rem", marginBottom: "1.438rem"}}>
                    <CustomInput
                        label="Nueva contraseña"
                        variant="filled"
                        type={showPassword1 ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <EyePassword show={showPassword1} toggle={togglePassword1}/>
                            ),
                            disableUnderline: true
                        }}
                        {...formResetPassword.register("password1")}
                    />

                </Box>
                <Box sx={{marginBottom: "0.813rem"}}>
                    <CustomInput
                        label="Confirmación de contraseña"
                        variant="filled"
                        type={showPassword2 ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <EyePassword show={showPassword2} toggle={togglePassword2}/>
                            ),
                            disableUnderline: true
                        }}
                        {...formResetPassword.register("password2")}
                    />

                </Box>

                <CustomAlertSuccess text={successMessage || ""} show={successMessage !== undefined}/>

                <CustomAlertDanger text={errorMessage || ""} show={errorMessage !== undefined}/>

                <Box marginTop={"1.375rem"}>
                    <ButtonCard
                        variant="contained"
                        disabled={forgotPasswordStatus.isLoading || !formResetPassword.formState.isValid || recoverPasswordStatus.isLoading}
                        type={"submit"}
                    >
                        <TextSpinner isLoading={recoverPasswordStatus.isLoading}
                                     text={" Crear contraseña"}
                                     textSpinner={"Cargando"}
                        />
                    </ButtonCard>
                </Box>
            </form>
        </Box>
    );
};