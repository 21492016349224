import {Rol} from "../utils/enum";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../store/store";

const useRolHook = ():string => {

    const rol = useSelector(
        (state: RootState) => state.currentUser.user.data?.user.type,
        shallowEqual,
    );

    return rol||"";

}

export default useRolHook;