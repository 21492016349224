import {DataHistory} from "../api/interface/transaction";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {pageInit} from "../../_fri/utils/enum";


export interface FilterClosingDetailState {
    selectedBranch: string;
    selectedStatus: string[];
    totalPages: number;
    currentPage: number;
    dataPicker1: string | null;
    dataPicker2: string | null;
    rangeAmount1: string;
    rangeAmount2: string;
    reference: string;
    userFri: string;
    dateRangeError: undefined | string;
    amountRangeError: undefined | string;

    detail: DataHistory | undefined;
}

const initialState: FilterClosingDetailState = {
    selectedBranch: "", //""===null
    selectedStatus: [], //""===null
    totalPages: -1,
    currentPage: pageInit,
    dataPicker1: null, //--bottom
    dataPicker2: null, //--top
    rangeAmount1: "", //bottom
    rangeAmount2: "", //--top
    reference: "",
    userFri: "",
    dateRangeError: undefined,
    amountRangeError: undefined,
    detail: undefined,
};

export const filterClosingDetailSlice = createSlice({
    name: "filterClosingDetail",
    initialState,
    reducers: {
        setTotalPage: (
            state,
            action: PayloadAction<number>
        ) => {
            state.totalPages = action.payload;
        },
        selectBranch: (state, action: PayloadAction<string>) => {
            state.selectedBranch = action.payload;
            state.currentPage = pageInit;
        },
        selectStatus: (state, action: PayloadAction<string>) => {
            state.selectedStatus = action.payload===""?[]: [action.payload];
            state.currentPage = pageInit;
        },
        setReference: (state, action: PayloadAction<string>) => {
            state.reference = action.payload;
            state.currentPage = pageInit;
        },
        setUserFri: (state, action: PayloadAction<string>) => {
            state.userFri = action.payload;
            state.currentPage = pageInit;
        },
        setDateErrorRange: (state, action: PayloadAction<string>) => {
            state.dateRangeError = action.payload;
        },

        setAmountRangeError: (state, action: PayloadAction<string>) => {
            state.amountRangeError = action.payload;
        },
        clean: (state) => {
            state.totalPages = -1;

            state.currentPage = pageInit;

            state.selectedBranch = "";

            state.selectedStatus = [];
            //""===null
            state.dataPicker1 = null;
            //--bottom
            state.dataPicker2 = null;
            //--top
            state.dateRangeError = undefined;

            state.rangeAmount1 = "";
            //bottom
            state.rangeAmount2 = "";
            //--top
            state.amountRangeError = undefined;
            state.reference = "";
            state.userFri = "";
        },
        setCurrentPage:(state, action: PayloadAction<number>)=>{
            state.currentPage =action.payload
        },
        setDataPicker: (state, action: PayloadAction<{ date1: string | null, date2: string | null }>) => {


            if (action.payload.date1 !== null && action.payload.date2 !== null) {

                const date1 = new Date(action.payload.date1);
                const date2 = new Date(action.payload.date2);

                if (date1 > date2) {
                    state.dateRangeError = "Por favor, asegúrese de ingresar una fecha final posterior a la fecha inicial";
                    return;
                }
                state.currentPage = pageInit;
            }

            state.dataPicker1 = action.payload.date1;
            state.dataPicker2 = action.payload.date2;

            state.dateRangeError = undefined;
        },
        setRangeAmount: (state, action: PayloadAction<{ amount1: string, amount2: string }>) => {

            if (action.payload.amount1 !== "" && action.payload.amount2 !== "") {

                if (isNaN(Number(action.payload.amount1))) {
                    state.amountRangeError = "Por favor, asegúrese de ingresar un monto inicial numérico";
                    return;
                }

                if (isNaN(Number(action.payload.amount2))) {
                    state.amountRangeError = "Por favor, asegúrese de ingresar un monto final numérico";
                    return;
                }

                if (Number(action.payload.amount1) > Number(action.payload.amount2)) {
                    state.amountRangeError = "Por favor, asegúrese de ingresar un monto final mayor al monto inicial";
                    return;
                }
                state.currentPage = pageInit;
            }


            state.rangeAmount1 = action.payload.amount1;
            state.rangeAmount2 = action.payload.amount2;

            state.amountRangeError = undefined;
        },
    },
});

export const {
    setTotalPage,
    selectBranch,
    selectStatus,
    setReference,
    setUserFri,
    clean,
    setCurrentPage,
    setDataPicker,
    setRangeAmount
} = filterClosingDetailSlice.actions;

export default filterClosingDetailSlice.reducer;