import {useSelector} from "react-redux";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import App from "../App";
import {AuthPage} from "../modules/auth/AuthPage";
import {RootState} from "../store/store";
import { PrivateRoute } from "./PrivateRoute";
import React from "react";
import {LinearProgress} from "@mui/material";
const {PUBLIC_URL} = process.env;
const AffiliationsPage=React.lazy(()=> import('../modules/affiliations/AffiliationsPage'))
export const AppRoute = () => {

    const user = useSelector((state: RootState) => state.currentUser);

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App/>}>
                    {user.user.sessionId ? (
                        <>
                            <Route path="/*" element={<PrivateRoute/>}/>
                            <Route index element={<Navigate to="/transaction"/>}/>
                        </>
                    ) : (
                        <>
                            <Route path="afiliaciones/*" element={<React.Suspense fallback={ <LinearProgress />}> <AffiliationsPage /></React.Suspense>}/>
                            <Route path="auth/*" element={<AuthPage/>}/>
                            <Route path="*" element={<Navigate to="/auth"/>}/>
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};