import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface MoneyWalletState {
    money: string;

}

const initialState: MoneyWalletState = {
    money: ""
};

export const moneyWalletSlice = createSlice({
    name:'moneyWalletRedux',
    initialState,
    reducers:{
        setMoney:(state,action:PayloadAction<string>)=>{
            state.money = action.payload;
        }
    }
})

export const {setMoney}= moneyWalletSlice.actions
export default moneyWalletSlice.reducer;
