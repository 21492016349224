import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {getHeader, getRTKErrorMessage} from "../../../_fri/utils/function";
import {
    ForgotPasswordRequest,
    ForgotPasswordResponse,
    RecoverPasswordRequest,
    RecoverPasswordResponse
} from "../interface/passwordRecovery";
import {Message, setErrorMessage, setSuccessMessage} from "../../slice/messageSlice";

export const passwordRecovery = createApi({
    reducerPath: 'passwordRecovery',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/business/password_recoveries/v1/`,
        prepareHeaders: (headers, {getState}) => {
            return getHeader(headers, getState);
        }
    }),
    endpoints: (builder) => ({
        forgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordRequest>({
            query: (data) => ({
                url: `forgot`,
                method: 'Post',
                body: data,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if("error" in result){
                    dispatch(setErrorMessage(getRTKErrorMessage(result.error.error as Error)));
                    return;
                }
                const data: Message={
                    message:"El código ha sido enviado con éxito"
                };
                dispatch(setSuccessMessage(data));
            }
        }),
        recoverPassword: builder.mutation<RecoverPasswordResponse, RecoverPasswordRequest>({
            query: (data) => ({
                url: `recover`,
                method: 'Post',
                body: data,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if("error" in result) {
                    dispatch(setErrorMessage(getRTKErrorMessage(result.error.error as Error)));
                    return;
                }
                const data: Message={
                    message:"Tu contraseña se cambio con éxito"
                };

                dispatch(setSuccessMessage(data));
            }
        }),
    }),
})

export const { useForgotPasswordMutation, useRecoverPasswordMutation } = passwordRecovery