import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {getHeader} from "../../../_fri/utils/function";
import {
    DeleteUserRequest,
    DeleteUserResponse,
    DownloadUserQrRequest,
    GetUsersRequest,
    GetUsersResponse,
    NotificationChangeRequest,
    NotificationChangeResponse,
    ResetPasswordUserRequest,
    ResetPasswordUserResponse,
    BlockUserRequest,
    BlockUserResponse,
    EditUserRequest,
    EditUserResponse,
    UnBlockUserRequest,
    UnBlockUserResponse, CreateUserResponse, CreateUserRequest,
} from "../interface/user";
import {Message, setSuccessMessage} from "../../slice/messageSlice";

export const user = createApi({
        reducerPath: "userApi",
        baseQuery: fetchBaseQuery({
            baseUrl: `${process.env.REACT_APP_API_URL}/business/users/v1/`,
            prepareHeaders: (headers, {getState}) => {
                return getHeader(headers, getState);
            },
        }),
        tagTypes: ["GetUsers"],
        endpoints: (builder) => ({
            getUsers: builder.query<GetUsersResponse, GetUsersRequest>({
                query: (data) => ({
                    url: `list`,
                    method: "Post",
                    body: data,
                }),
                providesTags: ["GetUsers"],
                async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                    const result = await queryFulfilled.catch((e) => ({error: e}));
                },
            }),
            notificationChange: builder.mutation<NotificationChangeResponse, NotificationChangeRequest>({
                query: (requestData) => ({
                    url: `notification-change`,
                    method: "POST",
                    body: requestData,
                }),
                invalidatesTags: ["GetUsers"],
                async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                    const result = await queryFulfilled.catch(e => ({error: e}));
                    if ("error" in result) {
                        return;
                    }
                    const data: Message = {
                        message: `Se actualizo con éxito`,
                        title: "Notificación"
                    };
                    dispatch(setSuccessMessage(data));
                }
            }),
            deleteUser: builder.mutation<DeleteUserResponse, DeleteUserRequest>({
                query: (requestData) => ({
                    url: `delete`,
                    method: "POST",
                    body: requestData,
                }),
                invalidatesTags: ["GetUsers"],
            }),
            downloadUserQr: builder.mutation<{ file: string, name: string }, DownloadUserQrRequest>({
                query: (requestData) => ({
                    url: `qr/download`,
                    method: "POST",
                    body: requestData,
                    responseHandler: async (response) => {
                        const buffer = await response.arrayBuffer();
                        const uint8Array = new Uint8Array(buffer);
                        const serializedArray = JSON.stringify(Array.from(uint8Array));
                        const name = response.headers.get("Content-Disposition")?.split("filename=")[1];
                        return {file: serializedArray, name: name};
                    }
                }),
            }),
            resetPasswordUser: builder.mutation<ResetPasswordUserResponse, ResetPasswordUserRequest>({
                query: (requestData) => ({
                    url: `password/reset`,
                    method: "POST",
                    body: requestData,
                }),
                invalidatesTags: ["GetUsers"],
            }),
            blockUser: builder.mutation<BlockUserResponse, BlockUserRequest>({
                query: (data) => ({
                    url: `block`,
                    method: "Post",
                    body: data,
                }),
                invalidatesTags: ["GetUsers"],
                async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                    const result = await queryFulfilled.catch((e) => ({error: e}));
                },
            }),
            unBlockUser: builder.mutation<UnBlockUserResponse, UnBlockUserRequest>({
                query: (data) => ({
                    url: `unblock`,
                    method: "Post",
                    body: data,
                }),
                invalidatesTags: ["GetUsers"],
                async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                    const result = await queryFulfilled.catch((e) => ({error: e}));
                },
            }),
            editUser: builder.mutation<EditUserResponse, EditUserRequest>({
                query: (data) => ({
                    url: `modify`,
                    method: "Post",
                    body: data,
                }),
                invalidatesTags: ["GetUsers"],
                async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                    const result = await queryFulfilled.catch((e) => ({error: e}));
                },
            }),
            createUser: builder.mutation<CreateUserResponse, CreateUserRequest>({
                query: (data) => ({
                    url: `create`,
                    method: "Post",
                    body: data,
                }),
                invalidatesTags: ["GetUsers"],
                async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                    const result = await queryFulfilled.catch((e) => ({error: e}));
                    if ("error" in result) {
                        return;
                    }
                    const data: Message = {
                        message: `@${arg.requestContent.username}`,
                        title: "Sucursal creada"
                    };
                    dispatch(setSuccessMessage(data));
                },
            }),
        }),
    })
;
export const {

    useGetUsersQuery,
    useNotificationChangeMutation,
    useDeleteUserMutation,
    useDownloadUserQrMutation,
    useEditUserMutation,
    useBlockUserMutation,
    useUnBlockUserMutation,
    useResetPasswordUserMutation,
    useCreateUserMutation
} = user;
