import {Box, Divider} from "@mui/material";
import {ArrowIcon, IconClosedBarWrap, MainHeader, SecondaryHeader} from "./Header.styles";
import {FlexFill} from "../../../../../themes/utils";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../store/store";
import {handleOpen} from "../../../../../../store/slice/drawerSlice";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import {LogoFriIcon} from "../../../../../icons/LogoFriIcon";
import {DrawerIcon} from "../../../../../icons/DrawerIcon";
import CustomWalletDrawer from "../../../../../components/CustomWalletDrawer";
import {theme} from "../../../../../themes/theme";

const Header = () => {

    const open = useSelector((state: RootState) => state.drawer.open);
    const dispatch = useDispatch();

    const handleDrawer = () => {
        dispatch(handleOpen());
    };

    return (
        <>
            {
                open &&
                <>
                    <MainHeader>
                        <Box sx={{display: "flex"}}>
                            <Box sx={{display: "flex", flex: 1, marginBottom:"0.35rem"}}>
                                <DrawerIcon/>
                            </Box>
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <ArrowIcon onClick={handleDrawer} sx={{marginRight: 1}}>
                                    <ArrowBackRoundedIcon sx={{fontSize: 15}}/>
                                </ArrowIcon>
                            </Box>
                        </Box>
                        <Box sx={{marginBottom:"1rem"}}>
                            <CustomWalletDrawer/>
                        </Box>
                        <Box>
                            <Divider sx={{borderColor:theme.palette.grey[300]}} />
                        </Box>
                    </MainHeader>
                    <SecondaryHeader>
                        <FlexFill>
                            Menú
                        </FlexFill>
                    </SecondaryHeader>
                </>
            }

            {!open &&
                <>

                    <IconClosedBarWrap>
                        <LogoFriIcon/>
                    </IconClosedBarWrap>

                    <SecondaryHeader>
                        <ArrowIcon onClick={handleDrawer}>
                            <ArrowForwardRoundedIcon sx={{fontSize: 15}}/>
                        </ArrowIcon>
                    </SecondaryHeader>

                </>
            }
        </>
    );
};

export default Header;