import {createTheme} from "@mui/material";
const { palette } = createTheme() // MUI's default theme

export const theme = createTheme(({
    typography: {
        fontFamily: "Roboto, sans-serif, Noto Sans",
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
    },
    palette: {
        mode: "light",
        primary: {
            dark: "#1177e0",
            main: "#0087FF",
            light: "#D9DCE0",
            contrastText: "#F1F8FF"
        },
        secondary: {
            main: "#FAFAFA",
            light: "#B8BDC5",
            dark: "#7A808B",
            contrastText: "#efefef"
        },
        text: {
            secondary: "#7A808B",
            primary: "#878E9A",
            disabled: "#747E8E"
        },
        common: {
            white: "#FFFFFF",
            black: "#000000"
        },
        action: {
            active: "#0087FF"
        },
        divider: "#7A808B",
        error: {
            main: "#FF3040",
            light: "#FCEEED"
        },
        success: {
            main: "#85C31A",
            light: "#F7FFE8",
            dark: "#84C318",
            contrastText: "#EBF5D9"

        },
        warning: {
            main: "#FFB60D",
            light: "#FFEFC980"
        },
        background: {
            default: "#F8F8F8"
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1680,
        }
    },
    transitions: {},
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontWeight: 500,
                    boxShadow: "none",
                    "&:hover": {
                        boxShadow: "none",
                    }
                }
            },
            defaultProps: {disableRipple: true}
        },
        MuiDialog: {
            defaultProps: {
                transitionDuration: 1,
                fullWidth: true,
                maxWidth: "sm"
            },
            styleOverrides: {
                root: {
                    "& .MuiDialog-paper": {
                        borderRadius: "1rem",
                        paddingBottom: "1rem"
                    }
                }
            }
        },
        MuiListItemText: {
            defaultProps: {
                primaryTypographyProps: {
                    fontWeight: 400
                }
            }
        },
        MuiRadio: {
            defaultProps: {disableRipple: true}
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    "&[disabled]": {
                        color: palette.action.disabled,
                        pointerEvents: "none",
                    },
                    cursor:'pointer'
                },
            }
        },
        MuiMenuItem:{
            defaultProps:{
                disableRipple:true
            },
            styleOverrides:{
                root:{
                    fontWeight:400
                }
            }
        }
    }

}));