import {alpha, styled} from "@mui/material/styles";
import {TextField} from "@mui/material";

export const CustomInput = styled(TextField)(({ theme }) => ({
    '& .MuiFilledInput-root': {
        overflow: 'hidden',
        borderRadius: "10px",
        backgroundColor: theme.palette.common.white,
        border: '0.125rem solid',
        borderColor: theme.palette.primary.light,
        color:theme.palette.common.black,
        fontWeight: theme.typography.fontWeightRegular,
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
    width: "100%",
    "& .MuiFormLabel-root":{
        color: theme.palette.secondary.dark,
        fontWeight: theme.typography.fontWeightRegular,
        paddingTop: "0.125rem",
        "& .MuiError": {
            color: theme.palette.secondary.dark,
        },
    },
    ".MuiIconButton-root":{
        color: theme.palette.secondary.dark
    },
    "& .MuiFormHelperText-root": {
        margin:"0.188rem 0rem 0rem 0.125rem",
    },
}));