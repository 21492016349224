import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {getHeader, sortDescDate} from "../../../_fri/utils/function";
import {
    filter,
    setPaidTransaction,
    setPendingTransaction,
} from "../../slice/filterTransactionSlice";
import {
    PaidOperatorTransactionRequest,
    PaidOperatorTransactionResponse,
    PendingOperatorTransactionRequest,
    PendingOperatorTransactionResponse
} from "../interface/transactionQueryOperators";
import {
    BalanceOperatorRequest,
    BalanceOperatorResponse,
    RefundPaymentOperatorRequest,
    RefundPaymentOperatorResponse,
    SendPaymentOperativeRequest,
    SendPaymentOperativeResponse
} from "../interface/transaction";
import {Message, setSuccessMessage} from "../../slice/messageSlice";
import {CancelPaymentAdminRequest, CancelPaymentAdminResponse} from "../interface/transactionQueryAdmin";
import {setMoney} from "../../slice/moneyWallet";

export const transactionQueryOperators = createApi({
    reducerPath: "transactionQueryOperators",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/business/transactions/v1/`,
        prepareHeaders: (headers, {getState}) => {
            return getHeader(headers, getState);
        }
    }),
    tagTypes: ["GetTransactionOperators","GetWalletOperator"],
    endpoints: (builder) => ({
        pendingTransactionOperator: builder.query<PendingOperatorTransactionResponse, PendingOperatorTransactionRequest>({
            query: (data) => ({
                url: `pending`,
                method: "Post",
                body: data,
            }),
            providesTags: ["GetTransactionOperators"],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }

                dispatch(setPendingTransaction(sortDescDate(result.data.responseContent.transactions)));
                dispatch(filter());
            }
        }),
        paidTransactionOperator: builder.query<PaidOperatorTransactionResponse, PaidOperatorTransactionRequest>({
            query: (data) => ({
                url: `paid`,
                method: "Post",
                body: data,
            }),
            providesTags: ["GetTransactionOperators"],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }

                dispatch(setPaidTransaction(sortDescDate(result.data.responseContent.transactions)));
                dispatch(filter());
            }
        }),
        sendPaymentOperative: builder.mutation<SendPaymentOperativeResponse, SendPaymentOperativeRequest>({
            query: (data) => ({
                url: `requests/send`,
                method: "Post",
                body: data,
            }),
            invalidatesTags: ["GetTransactionOperators","GetWalletOperator"],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch((e) => ({error: e}));
                if ("error" in result) {
                    return;
                }
                const data: Message = {
                    message: `a ${result.data.responseContent.transaction.friUser.username} por ${result.data.responseContent.transaction.formattedAmount}`,
                    title: "Solicitud",
                };

                dispatch(setSuccessMessage(data));
            },
        }),
        cancelPaymentOperative: builder.mutation<CancelPaymentAdminResponse, CancelPaymentAdminRequest>({
            query: (data) => ({
                url: `requests/cancel`,
                method: "Post",
                body: data,
            }),
            invalidatesTags:["GetTransactionOperators", "GetWalletOperator"],
        }),
        refundPaymentOperative: builder.mutation<RefundPaymentOperatorResponse, RefundPaymentOperatorRequest>({
            query: (data) => ({
                url: `refund`,
                method: "Post",
                body: data,
            }),
            invalidatesTags: ["GetTransactionOperators","GetWalletOperator"],
        }),
        balanceOperator: builder.query<
            BalanceOperatorResponse,
            BalanceOperatorRequest
        >({
            query: (requestData) => ({
                url: `balance`,
                method: "POST",
                responseType: "arrayBuffer",
                body: requestData
            }),
            providesTags: ["GetWalletOperator"],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch((e) => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(
                    setMoney(result.data.responseContent?.balanceDetail?.payments)
                );
            }
        }),
    })
});

export const {
    usePendingTransactionOperatorQuery,
    usePaidTransactionOperatorQuery,
    useSendPaymentOperativeMutation,
    useCancelPaymentOperativeMutation,
    useRefundPaymentOperativeMutation,
    useBalanceOperatorQuery
} = transactionQueryOperators;