import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {getHeader, parseFilename} from "../../../_fri/utils/function";
import {
    ClosingTransactionListRequest,
    ClosingTransactionListResponse,
    DownloadClosingTransactionListRequest
} from "../interface/closingDetail";
import {setTotalPage} from "../../slice/filterClosingDetailSlice";

export const closingDetail = createApi({
    reducerPath: "closingDetail",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/business/closing/`,
        prepareHeaders: (headers, {getState}) => {
            return getHeader(headers, getState);
        },
    }),
    tagTypes: ["closingDetail"],
    endpoints: (builder) => ({
        closingTransactionList: builder.query<ClosingTransactionListResponse, ClosingTransactionListRequest>({
            query: (data) => ({
                url: `v2/transactions/list`,
                method: "Post",
                body: data,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch((e) => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(setTotalPage(result.data.responseContent.pageProperties.totalPages));
            }
            ,
        }),
        downloadClosingTransactionList: builder.mutation<{ file: string, name: string }, DownloadClosingTransactionListRequest>({
            query: (requestData) => ({
                url: `v2/transactions/excel`,
                method: "POST",
                responseType: "arrayBuffer",
                body: requestData,
                responseHandler: async (response) => {
                    const buffer = await response.arrayBuffer();
                    const uint8Array = new Uint8Array(buffer);
                    const serializedArray = JSON.stringify(Array.from(uint8Array));
                    const name = response.headers.get("Content-Disposition");
                    return {file: serializedArray, name: parseFilename(name)};
                }
            })
        })
    })
})

export const {
    useClosingTransactionListQuery,
    useDownloadClosingTransactionListMutation
} = closingDetail;
