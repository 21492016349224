import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {getHeader} from "../../../_fri/utils/function";
import {
    BusinessSearchToggleRequest,
    BusinessSearchToggleResponse,
    ClosingTypeChangeRequest,
    ClosingTypeChangeResponse,
    DownloadQrRequest,
    FundSourceRequest,
    FundSourceResponse,
    NotificationsCatalogsRequest,
    NotificationsCatalogsResponse,
    ProfileAvatarResponse,
    UpdateEmailRequest,
    UpdateEmailResponse,
    UpdatePhoneRequest,
    UpdatePhoneResponse,
    UserAssignableRequest,
    UserAssignableResponse,
    ValidateEmailRequest,
    ValidateEmailResponse
} from "../interface/admin";
import {IndexProfileModal, setChangeEmail, setIndexProfileModal} from "../../slice/profileSlice";
import {
    setAvatar,
    setClosingType,
    setEmailCurrentUser,
    setPhoneCurrentUser,
    setSearchEnabled
} from "../../slice/currentUserSlice";
import {Message, setSuccessMessage} from "../../slice/messageSlice";
import {setAssignableUsers} from "../../slice/assinableUsersSlice";


export const admin = createApi({
    reducerPath: "admin",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/business/users/`,
        prepareHeaders: (headers, {getState}) => {
            return getHeader(headers, getState);
        }
    }),
    endpoints: (builder) => ({
        assignable: builder.mutation<UserAssignableResponse, UserAssignableRequest>({
            query: (data) => ({
                url: `v1/list/assignable`,
                method: "Post",
                body: data,
                providesTags: ["Post"],
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(setAssignableUsers(result.data.responseContent.users));
            }
        }),
        updateEmail: builder.mutation<UpdateEmailResponse, UpdateEmailRequest>({
            query: (data) => ({
                url: "v2/profile/update-email",
                method: "Post",
                body: data,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(setIndexProfileModal(IndexProfileModal.emailCode));
                dispatch(setChangeEmail(arg.requestContent.email));
            }
        }),
        validateEmailChange: builder.mutation<ValidateEmailResponse, ValidateEmailRequest>({
            query: (data) => ({
                url: "v2/profile/validate-email-change",
                method: "Post",
                body: data,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(setEmailCurrentUser(arg.requestContent.email));
                dispatch(setIndexProfileModal(IndexProfileModal.none));

                const data: Message = {
                    message: `Se actualizo con éxito`,
                    title: "Correo"
                };

                dispatch(setSuccessMessage(data));

            }
        }),
        updatePhone: builder.mutation<UpdatePhoneResponse, UpdatePhoneRequest>({
            query: (data) => ({
                url: "v2/profile/update-phone",
                method: "Post",
                body: data,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(setPhoneCurrentUser(arg.requestContent.phoneNumber));
                dispatch(setIndexProfileModal(IndexProfileModal.none));
                const data: Message = {
                    message: `Se actualizo con éxito`,
                    title: "Teléfono"
                };
                dispatch(setSuccessMessage(data));
            }
        }),
        businessSearchToggle: builder.mutation<BusinessSearchToggleResponse, BusinessSearchToggleRequest>({
            query: (data) => ({
                url: "v1/profile/business-search-toggle",
                method: "Post",
                body: data,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(setSearchEnabled());
            }
        }),
        fundSource: builder.query<FundSourceResponse, FundSourceRequest>({
            query: (data) => ({
                url: "v2/profile/get-fund-source",
                method: "Post",
                body: data,
            }),
        }),
        closingTypeChange: builder.mutation<ClosingTypeChangeResponse, ClosingTypeChangeRequest>({
            query: (data) => ({
                url: "v1/profile/closing-type-change",
                method: "Post",
                body: data,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(setClosingType());

                const data: Message = {
                    message: `Se actualizo con éxito`,
                    title: "Tipo de cierre"
                };
                dispatch(setSuccessMessage(data));
            }
        }),
        downloadQr: builder.mutation<{ file: string, name: string }, DownloadQrRequest>({
            query: (requestData) => ({
                url: `v2/qr/download`,
                method: "POST",
                responseType: "arrayBuffer",
                body: requestData,
                responseHandler: async (response) => {
                    const buffer = await response.arrayBuffer();
                    const uint8Array = new Uint8Array(buffer);
                    const serializedArray = JSON.stringify(Array.from(uint8Array));
                    const name = response.headers.get("Content-Disposition")?.split("filename=")[1];
                    return {file: serializedArray, name: name};
                }
            })
        }),
        profileAvatar: builder.mutation<ProfileAvatarResponse, FormData>({
            query: (requestData) => ({
                url: `v1/profile/avatar`,
                method: "POST",
                body: requestData,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(setAvatar(result.data.responseContent.avatar));

                const data: Message = {
                    message: `Se actualizo con éxito`,
                    title: "Foto de perfil"
                };
                dispatch(setSuccessMessage(data));

            }
        }),
        notificationCatalogs: builder.mutation<NotificationsCatalogsResponse, NotificationsCatalogsRequest>({
            query: (requestData) => ({
                url: `v1/notification/catalogs`,
                method: "POST",
                body: requestData,
            }),
        }),

    })
});

export const {
    useAssignableMutation,
    useUpdateEmailMutation,
    useValidateEmailChangeMutation,
    useUpdatePhoneMutation,
    useBusinessSearchToggleMutation,
    useFundSourceQuery,
    useClosingTypeChangeMutation,
    useDownloadQrMutation,
    useProfileAvatarMutation,
    useNotificationCatalogsMutation,
} = admin;