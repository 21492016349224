import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {getHeader, sortDescDate} from "../../../_fri/utils/function";
import {
    PaidTransactionRequest,
    PaidTransactionResponse,
    PendingTransactionRequest,
    PendingTransactionResponse,
    Transaction,
    UnassignedTransactionRequest,
    UnassignedTransactionResponse,
    AssignPaymentAdminRequest,
    AssignPaymentAdminResponse,
    CancelPaymentAdminRequest,
    CancelPaymentAdminResponse,
    RefundPaymentAdminRequest,
    RefundPaymentAdminResponse,
    RejectPaymentAdminRequest,
    RejectPaymentAdminResponse,
    SendingPaymentAdminRequest,
    SendingPaymentAdminResponse
} from "../interface/transactionQueryAdmin";
import {
    filter,
    setPaidTransaction,
    setPendingTransaction,
    setUnassignedTransaction
} from "../../slice/filterTransactionSlice";
import {Message, setSuccessMessage} from "../../slice/messageSlice";
import {BalanceAdminRequest, BalanceAdminResponse} from "../interface/transaction";
import {setMoney} from "../../slice/moneyWallet";

export const transactionQueryAdmin = createApi({
    reducerPath: "transactionQueryAdmin",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/business/transactions/v1/admin/`,
        prepareHeaders: (headers, {getState}) => {
            return getHeader(headers, getState);
        }
    }),
    tagTypes: ["GetTransaction","GetWallet"],
    endpoints: (builder) => ({
        unassignedTransaction: builder.query<UnassignedTransactionResponse, UnassignedTransactionRequest>({
            query: (data) => ({
                url: `unassigned`,
                method: "Post",
                body: data,
            }),
            providesTags: ["GetTransaction"],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(setUnassignedTransaction(sortDescDate(result.data.responseContent.transactions)));
                dispatch(filter());
            },
        }),
        pendingTransaction: builder.query<PendingTransactionResponse, PendingTransactionRequest>({
            query: (data) => ({
                url: `pending`,
                method: "Post",
                body: data,
            }),
            providesTags: ["GetTransaction"],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }

                const data: Transaction[] = [];

                result.data.responseContent.transactionGroups.map((item) => {
                    item.transactions.map((transaction) => {
                        data.push({...transaction, userTransaction: item.user});
                    });
                });

                dispatch(setPendingTransaction(sortDescDate(data)));
                dispatch(filter());
            }
        }),
        paidTransaction: builder.query<PaidTransactionResponse, PaidTransactionRequest>({
            query: (data) => ({
                url: `paid`,
                method: "Post",
                body: data,
            }),
            providesTags: ["GetTransaction"],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }

                const data: Transaction[] = [];

                result.data.responseContent.transactionGroups.map((item) => {
                    item.transactions.map((transaction) => {
                        data.push({...transaction, userTransaction: item.user});
                    });
                });

                dispatch(setPaidTransaction(sortDescDate(data)));
                dispatch(filter());
            }
        }),
        sendPaymentAdmin: builder.mutation<SendingPaymentAdminResponse, SendingPaymentAdminRequest>({
            query: (data) => ({
                url: `requests/send`,
                method: "Post",
                body: data,
            }),
            invalidatesTags: ["GetTransaction","GetWallet"],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }
                const data: Message = {
                    message: `a ${result.data.responseContent.transaction.friUser.username} por ${result.data.responseContent.transaction.formattedAmount}`,
                    title: "Solicitud"
                };
                dispatch(setSuccessMessage(data));
            }
        }),
        cancelPaymentAdmin: builder.mutation<CancelPaymentAdminResponse, CancelPaymentAdminRequest>({
            query: (data) => ({
                url: `requests/cancel`,
                method: "Post",
                body: data,
            }),
            invalidatesTags: ["GetTransaction","GetWallet"]
        }),
        rejectPaymentAdmin: builder.mutation<RejectPaymentAdminResponse, RejectPaymentAdminRequest>({
            query: (data) => ({
                url: `payments/reject`,
                method: "Post",
                body: data,
            }),
            invalidatesTags: ["GetTransaction","GetWallet"],
        }),
        assignPaymentAdmin: builder.mutation<AssignPaymentAdminResponse, AssignPaymentAdminRequest>({
            query: (data) => ({
                url: `payments/assign`,
                method: "Post",
                body: data,
            }),
            invalidatesTags: ["GetTransaction","GetWallet"]
        }),
        refundPaymentAdmin: builder.mutation<RefundPaymentAdminResponse, RefundPaymentAdminRequest>({
            query: (data) => ({
                url: `refund`,
                method: "Post",
                body: data,
            }),
            invalidatesTags: ["GetTransaction","GetWallet"]
        }),
        balanceAdmin: builder.query<BalanceAdminResponse, BalanceAdminRequest>({
            query: (data) => ({
                url: `balance`,
                method: "Post",
                body: data,
            }),
            providesTags: ["GetWallet"],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch((e) => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(
                    setMoney(result.data.responseContent?.balanceDetail?.payments)
                );
            }
        }),
    })
});


export const {
    usePaidTransactionQuery,
    usePendingTransactionQuery,
    useUnassignedTransactionQuery,
    useSendPaymentAdminMutation,
    useCancelPaymentAdminMutation,
    useRejectPaymentAdminMutation,
    useAssignPaymentAdminMutation,
    useRefundPaymentAdminMutation,
    useBalanceAdminQuery
} = transactionQueryAdmin;