import {FlexFillColumn} from "../../../../themes/utils";
import {TransactionIcon} from "../../../../icons/TransactionIcon";
import {DocumentIcon} from "../../../../icons/DocumentIcon";
import {ShopIcon} from "../../../../icons/ShopIcon";
import {UserIcon} from "../../../../icons/UserIcon";
import {ClockIcon} from "../../../../icons/ClockIcon";
import Item from './Item/index'
import useRolHook from "../../../../hooks/useRolHook";
import {Rol} from "../../../../utils/enum";


export const ItemList = () => {

    const rol = useRolHook();

    return (
        <FlexFillColumn>

            <Item icon={<TransactionIcon/>} text={"Pagos"} index={0} redirect={'/transaction/dashboard'}></Item>

            <Item icon={<ClockIcon/>} text={"Historial de pagos"} redirect={'/transaction/history'} index={1}></Item>

            <Item icon={<DocumentIcon/>} text={"Cierres"} redirect={'/closing/history'} index={2}></Item>

            {rol===Rol.ADMIN && <Item icon={<ShopIcon/>} text={"Admon. de sucursales"} index={3} redirect={'/user'}></Item>}

            {/*<Item icon={<MoneyIcon/>}  text={"Pago a negocios"} index={4}></Item>*/}

            {/*<Item icon={<CalendarIcon/>}  text={"fri Shows"} index={5}></Item>*/}

            <Item  icon={<UserIcon/>} text={"Perfil fri"} index={6}  redirect={'/profile'}></Item>

        </FlexFillColumn>
    );
};