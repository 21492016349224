import {Box} from "@mui/material";
import {theme} from "../../themes/theme";


interface ICustomWallet{
    money:string
}

export const CustomWallet = ({money}:ICustomWallet) => {
    return (
        <Box>
            <Box sx={{
                padding: "0.2rem",
                width:"100%",
                color: theme.palette.common.black,
                fontSize:"1rem",
                fontWeight:theme.typography.fontWeightMedium

            }}>
                {money}
            </Box>
            <Box sx={{fontWeight:theme.typography.fontWeightLight, fontSize:"0.875rem"}}>
                Saldo total
            </Box>
        </Box>
    );
};