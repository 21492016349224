import {isRejectedWithValue, Middleware, MiddlewareAPI} from '@reduxjs/toolkit'
import {logOut} from '../../_fri/utils/function'

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const {payload, meta} = action
        const {status} = payload
        const unprotectedEndpoints = ['credentials', 'forgotPassword', 'recoverPassword']
        const shouldLogOut = status === 401 && !unprotectedEndpoints.includes(meta.arg.endpointName)

        if (shouldLogOut) {
            logOut()
        }

        action.isError = true
    }

    return next(action)
}
