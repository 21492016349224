import {FlexFillCenter} from "../../../../_fri/themes/utils";
import {Box, Divider} from "@mui/material";
import {TextFriICon} from "../../../../_fri/icons/TextFriICon";
import {theme} from "../../../../_fri/themes/theme";

export const CardHeader = () => {
    return (
        <Box>
            <FlexFillCenter>
                <TextFriICon/>
            </FlexFillCenter>
            <Box sx={{paddingTop: "1.375rem", paddingBottom: "1.375rem"}}>
                <FlexFillCenter>
                    <Divider sx={{width: 165, borderColor: theme.palette.primary.light}} variant={"middle"}/>
                </FlexFillCenter>
            </Box>
        </Box>
    );
};