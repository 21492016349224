import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";
import {FlexFillCenter} from "../../../_fri/themes/utils";

export const LoginWrap = styled(Box)(({theme}) => ({
    backgroundImage: "url(\"/background-login.png\")",
    backgroundSize: "cover",
    boxSizing: "border-box",
    minHeight: "100vh",
    position: "relative",
    display: "flex",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column"
    }
    ,
    top:0
}));

export const LoginLeftWrap = styled(FlexFillCenter)(({theme}) => ({

    marginBottom: "25vh",

    [theme.breakpoints.down("md")]: {
        flex: 0,
        paddingTop: "1.25rem",
        marginBottom: "0vh",
    }

}));

export const LoginRightWrap = styled(FlexFillCenter)(({theme}) => ({}));

export const CardLoginWrap = styled(Box)(({theme}) => ({
    width: "30.625rem",
    height: "50rem",
    borderRadius: "0.938rem",
    backgroundColor: theme.palette.secondary.light,
    paddingLeft: "5vw",
    paddingRight: "5vw",
    margin: "2vh",
    background: theme.palette.common.white,
}));


export const LoginTextLeftWrap = styled(FlexFillCenter)(({theme}) => ({
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
}));
