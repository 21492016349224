import {ButtonCard, TitleCardLoginWrap} from "./CardLogin.styles";
import {Box, Checkbox, FormGroup, Link, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import usePasswordHook from "../../../../_fri/hooks/usePasswordHook";
import {useCredentialsMutation} from "../../../../store/api/service/auth";
import {SubmitHandler, useForm} from "react-hook-form";
import {Login, schemaLogin} from "../core/_model";
import {yupResolver} from "@hookform/resolvers/yup";
import {EyePassword} from "../../../../_fri/components/CustomEyePassword";
import {CredentialRequest} from "../../../../store/api/interface/auth";
import {TextSpinner} from "../../../../_fri/components/CustomTextSpinner";
import {Module, useCleanMessageHook} from "../../../../_fri/hooks/useCleanMessageHook";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {CardHeader} from "./CardHeader";
import {CustomInput} from "../../../../_fri/components/CustomInput";
import CustomAlertSuccess from "../../../../_fri/components/CustomAlert/CustomAlertSuccess";
import CustomAlertDanger from "../../../../_fri/components/CustomAlert/CustomAlertDanger";
import {deleteLocalStorage, getLocalStorage, setLocalStorage} from "../../../../_fri/utils/function";
import {KeyLocalStorage} from "../../../../_fri/utils/enum";
import {theme} from "../../../../_fri/themes/theme";
import React, {useState} from "react";

export const CardLogin = () => {

    const [togglePassword, showPassword] = usePasswordHook();
    const navigate = useNavigate();
    const [credential, credentialStatus] = useCredentialsMutation();
    const cleanMessage = useCleanMessageHook(Module.login);
    const successMessage = useSelector((state: RootState) => state.message.successMessage);
    const errorMessage = useSelector((state: RootState) => state.message.errorMessage);
    const userRememberStorage = getLocalStorage(KeyLocalStorage.REMEMBERED);
    const userRemember = (userRememberStorage === null || userRememberStorage === undefined) ? "" : userRememberStorage;
    const [remember, setRemember] = useState(userRemember!=="");

    const formLogin = useForm<Login>({
        resolver: yupResolver(schemaLogin), defaultValues: {username: userRemember, remember: userRemember!==""}
    });

    const onSubmit: SubmitHandler<Login> = (value) => {

        if (value.remember) {
            setLocalStorage(KeyLocalStorage.REMEMBERED, value.username);
        }else{
            deleteLocalStorage(KeyLocalStorage.REMEMBERED)
        }

        const data: CredentialRequest = {
            username: value.username,
            password: value.password
        };

        credential(data);
    };


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRemember(event.target.checked);
    };

    return (
        <Box>
            <CardHeader/>

            <TitleCardLoginWrap sx={{paddingBottom: "1.875rem"}}>
                Inicio de sesión
            </TitleCardLoginWrap>

            <form onSubmit={formLogin.handleSubmit(onSubmit)}>

                <Box sx={{marginTop: "1.875rem"}}>
                    <CustomInput
                        {...formLogin.register("username")}
                        label="Usuario"
                        variant="filled"
                        InputProps={{disableUnderline: true}}
                    />
                </Box>
                <Box sx={{marginTop: "1rem", marginBottom: "0.938rem"}}>
                    <CustomInput
                        {...formLogin.register("password")}
                        label="Contraseña"
                        variant="filled"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <EyePassword show={showPassword} toggle={togglePassword}/>
                            ),
                            disableUnderline: true
                        }}
                    />

                </Box>


                <CustomAlertDanger text={errorMessage || ""} show={errorMessage !== undefined}/>

                <CustomAlertSuccess text={successMessage || ""} show={successMessage !== undefined}/>


                <Box sx={{marginTop: "1.25rem"}}>
                    <ButtonCard
                        variant="contained"
                        type={"submit"}
                        disabled={credentialStatus.isLoading || (!formLogin.formState.isValid)}
                    >
                        <TextSpinner isLoading={credentialStatus.isLoading}
                                     text={"Iniciar sesión"}
                                     textSpinner={"Cargando"}
                        />
                    </ButtonCard>
                </Box>

                <Box sx={{fontWeight: 600, cursor: "pointer", marginTop: "1.563rem", display: "flex"}}>
                    <Box sx={{
                        display: "flex",
                        flex: 1,
                    }}>
                        <Typography
                            sx={{color: theme.palette.text.secondary}}>
                            Recordarme

                        </Typography>

                        <Checkbox checked={remember} {...formLogin.register("remember",{onChange: (e) => handleChange(e)})} sx={{padding: 0, margin: 0, marginLeft: 1}}/>
                    </Box>
                    <Box>
                        <Link
                            onClick={() => {
                                navigate("/auth/forgot-password");
                            }}
                            underline="hover"
                            type={"button"}
                            component="button"
                        >
                            <Typography>
                                ¿Olvidaste tu contraseña?
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </form>


        </Box>
    );
};