import {styled} from "@mui/material/styles";
import {FlexFillColumn} from "../../../themes/utils";

export const Content = styled(FlexFillColumn)(({theme}) => ({
    marginTop: "0.563rem",
    marginBottom: "0.563rem",
    marginLeft: "0.688rem",
    marginRight: "0.688rem",
    [theme.breakpoints.down("md")]: {
        marginTop: "4.313rem",
    },
    backgroundColor: theme.palette.secondary.main
}));