import {cleanErrorMessage, cleanSuccessMessage} from "../../store/slice/messageSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {RootState} from "../../store/store";

export enum Module {
    login = 0
}

export const useCleanMessageHook = (module?: Module): [() => void] => {
    const successMessage = useSelector((state: RootState) => state.message.successMessage);
    const errorMessage = useSelector((state: RootState) => state.message.errorMessage);
    const dispatch = useDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {
            clean();
        }, 6000);

        return () => {
            clearTimeout(timer);
        };
    }, [successMessage, errorMessage]);

    useEffect(() => {
        if (module === Module.login) return; //arreglar despues
        clean();
    }, []);

    const clean = () => {
        dispatch(cleanErrorMessage());
        dispatch(cleanSuccessMessage());
    };
    return [clean];
};