import {fetchBaseQuery, createApi} from "@reduxjs/toolkit/dist/query/react";
import {
    BanksResponse,
    CheckUsernameResponse,
    CitiesResponse, CreateBusinessRequest,
    CreateBusinessResponse, FundSourceRequest, FundSourceResponse,
    StateResponse, UploadFileResponse
} from "../interface/signup";

export const signup = createApi({
    reducerPath: "signup",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/business/signup/v2/`,
    }),
    endpoints: (builder) => ({
        states: builder.query<StateResponse, undefined>({
            query: () => ({
                url: `states`,
                method: "Get",
            })
        }),
        cities: builder.query<CitiesResponse, string>({
            query: (data) => ({
                url: `cities/${data}`,
                method: "Get",
            })
        }),
        banks: builder.query<BanksResponse, undefined>({
            query: () => ({
                url: `banks`,
                method: "Get",
            })
        }),
        checkUsername: builder.mutation<CheckUsernameResponse, string>({
            query: (data) => ({
                url: `check-username/${data}`,
                method: "Get",
            })
        }),
        createBusiness: builder.mutation<CreateBusinessResponse, { request:CreateBusinessRequest, token:string }>({
            query: (data) => ({
                url: `create`,
                method: "Post",
                body: data.request,
                headers:{recaptchaToken:data.token}
            })
        }),
        uploadFiles: builder.mutation<UploadFileResponse, { request:FormData, token:string }>({
            query: (data) => ({
                url: `upload`,
                method: "Post",
                body: data.request,
                headers:{recaptchaToken:data.token}
            })
        }),
        fundSource: builder.mutation<FundSourceResponse, { request:FundSourceRequest, token:string }>({
            query: (data) => ({
                url: `fundsource`,
                method: "Post",
                body: data.request,
                headers:{recaptchaToken:data.token}
            })
        }),

    })
});

export const {
    useStatesQuery,
    useLazyCitiesQuery,
    useBanksQuery,
    useCheckUsernameMutation,
    useCreateBusinessMutation,
    useUploadFilesMutation,
    useFundSourceMutation
} = signup;