import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {User} from "../../interfaces/user";
import {initializeEmptyUser} from "../../_fri/utils/function";

export enum IndexUserModal {
    notifications = 1,
    delete=2,
    qr=3,
    passReset = 7,
    block=4,
    unblock=5,
    edit=6,
    new=8,
    none = 0
}


export interface UserModalState {
    indexUserModal: IndexUserModal
    user: User,
}

const initialState: UserModalState = {
    indexUserModal: IndexUserModal.none,
    user: initializeEmptyUser(),
};

export const userModalSlice = createSlice({
    name: "userModal",
    initialState,
    reducers: {
        setIndexUserModal: (state, action: PayloadAction<{ index: IndexUserModal, user?: User }>) => {
            state.indexUserModal = action.payload.index;
            if (action.payload.user === undefined) return;
            state.user = action.payload.user;
        },
    }
});

export const {setIndexUserModal} = userModalSlice.actions;

export default userModalSlice.reducer;