import * as React from 'react'
import {Box, Divider} from '@mui/material'
import {GenericFooter, SvgWrap, TextWrap} from './Item/Item.styles'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../store/store'
import {useLogOutMutation} from '../../../../../store/api/service/auth'
import {LogoutIcon} from "../../../../icons/LogoutIcon";

export const Footer = () => {
    const open = useSelector((state: RootState) => state.drawer.open)
    const information = useSelector(
        (state: RootState) => state.currentUser.information,
        shallowEqual
    )
    const [logOut] = useLogOutMutation()

    const handleLogout = () => {
        logOut({info: information})
    }

    return (
        <>
            <Box paddingRight={3} paddingLeft={3}>
                <Divider sx={{marginBottom: '1'}} />
            </Box>
            <GenericFooter tabIndex={0} onClick={handleLogout}>
                <SvgWrap>
                    <LogoutIcon/>
                </SvgWrap>
                <TextWrap>{open && 'Cerrar sesión'}</TextWrap>
            </GenericFooter>
        </>
    )
}
