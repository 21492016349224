import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {
    createSessionTime,
    getHeader,
    getRTKErrorMessage, logOut,
    setLocalStorage,
} from "../../../_fri/utils/function";
import {
    ChangePasswordRequest, ChangePasswordResponse,
    CredentialRequest,
    CredentialResponse,
    LogOutRequest,
    LogOutResponse,
} from "../interface/auth";
import {saveSession} from '../../slice/currentUserSlice'
import {KeyLocalStorage} from '../../../_fri/utils/enum'
import {cleanSuccessMessage, Message, setErrorMessage, setSuccessMessage} from "../../slice/messageSlice";

export const auth = createApi({
    reducerPath: 'auth',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/business/auth/`,
        prepareHeaders: (headers, {getState}) => {
            return getHeader(headers, getState)
        },
    }),
    endpoints: (builder) => ({
        credentials: builder.mutation<CredentialResponse, CredentialRequest>({
            query: (data) => ({
                url: `v2/credentials`,
                method: 'Post',
                body: data,
                providesTags: ['Post'],
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                dispatch(cleanSuccessMessage())
                const result = await queryFulfilled.catch((e) => ({error: e}))
                if ('error' in result) {
                    dispatch(setErrorMessage(getRTKErrorMessage(result.error.error as Error)))
                    return
                }
                dispatch(saveSession(result.data.responseContent))
                setLocalStorage(KeyLocalStorage.USER, JSON.stringify(result.data.responseContent))
                localStorage.setItem(
                    KeyLocalStorage.TIMEOUT,
                    createSessionTime(KeyLocalStorage.TIMEOUTVALUE)
                )
            },
        }),
        logOut: builder.mutation<LogOutResponse, LogOutRequest>({
            query: (data) => ({
                url: `v1/logout`,
                method: 'Post',
                body: data,
                providesTags: ['Post'],
            }),
            async onQueryStarted(arg, {queryFulfilled}) {
                await queryFulfilled.catch(e => ({error: e}));
                logOut();
            },
        }),
        changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordRequest>({
            query: (data)=>({
                url:'v1/change_password',
                method:'Post',
                body:data
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch(e => ({error: e}));
                if ("error" in result) {
                    return;
                }
                const data: Message = {
                    message: `Se actualizo con éxito`,
                    title: "Contraseña"
                };

                dispatch(setSuccessMessage(data));
            },

        })
    }),
})

export const {useCredentialsMutation, useLogOutMutation, useChangePasswordMutation} = auth
