import {GenericItem, SvgWrap, TextWrap} from "./Item.styles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../store/store";
import {handleOpen, selectItem} from "../../../../../../store/slice/drawerSlice";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

interface Item {
    text: string;
    index: number;
    redirect: string;
    icon: React.ReactNode;
}

const Item = ({text, index, redirect,icon}: Item) => {

    const selectedItem = useSelector((state: RootState) => state.drawer.selectedItem) === index;

    const open = useSelector((state: RootState) => state.drawer.open);

    const dispatch = useDispatch();

    const backgroundColor = selectedItem ? "white" : "transparent";
    const fontColor = selectedItem ? "black" : "";
    const theme = useTheme();
    const breakPointMobile = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const currentPath = window.location.pathname;
    useEffect(() => {
        if (currentPath.includes(redirect)) {
            dispatch(selectItem(index));
        }
    }, [currentPath]);

    const onClickSelectItem = () => {
        //---ux
        navigate(redirect);
        //---ui
        dispatch(selectItem(index));
        if (!breakPointMobile) return;
        dispatch(handleOpen());
    };


    return (
        <GenericItem onClick={onClickSelectItem} sx={{backgroundColor: backgroundColor, color: fontColor}}>
            <SvgWrap>
                {icon}
            </SvgWrap>
            <TextWrap>
                {open && text}
            </TextWrap>
        </GenericItem>
    );
};

export default Item;