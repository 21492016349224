import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export enum IndexProfileModal{
    none=0,
    nameBusiness =1,
    nameBranch =2,
    email=3,
    emailCode=5,
    phoneNumber=4,
    avatar=6
}

export interface ProfileState {
    indexProfileModal: number;
    changeEmail: string;
}

const initialState: ProfileState = {
    indexProfileModal: 0, //0-> no show modal
    changeEmail: ""
};

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setIndexProfileModal: (state, action: PayloadAction<IndexProfileModal>) => {
            state.indexProfileModal = action.payload;
            if (state.indexProfileModal === 0) {
                state.changeEmail = "";
            }
        },
        setChangeEmail: (state, action: PayloadAction<string>) => {
            state.changeEmail = action.payload; //correo nuevo
        }
    }
});

export const {setIndexProfileModal, setChangeEmail} = profileSlice.actions;
export default profileSlice.reducer;