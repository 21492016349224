import {Box, Typography} from "@mui/material";
import {Outlet} from "react-router-dom";
import {FlexFillColumnCenter} from "../../../_fri/themes/utils";
import {
    CardLoginWrap,
    LoginLeftWrap,
    LoginRightWrap,
    LoginTextLeftWrap,
    LoginWrap
} from "./Login.styles";
import {TextFriLightIcon} from "../../../_fri/icons/TextFriLightIcon";


export const Login = () => {

    return (
        <LoginWrap>
            <LoginLeftWrap>
                <Box>
                    <LoginTextLeftWrap>
                        <Typography sx={{fontSize: "2.563rem"}}>
                            Bienvenido a
                        </Typography>
                    </LoginTextLeftWrap>
                    <Box>
                        <TextFriLightIcon/>
                    </Box>
                </Box>
            </LoginLeftWrap>


            <LoginRightWrap>
                <CardLoginWrap>
                    <FlexFillColumnCenter>
                        <Outlet/>
                    </FlexFillColumnCenter>
                </CardLoginWrap>
            </LoginRightWrap>
        </LoginWrap>
    );
};