import {ClosingData} from "../api/interface/closing";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {pageInit} from "../../_fri/utils/enum";

export enum IndexClosuresToBeMadeModal {
    none = -1,
    modalAdmin = 1,
    modalOperator = 2
}

export interface ClosingState {
    history: ClosingData[];
    totalPages: number;
    currentPage: number;
    selectedBranch: string;
    selectedStatus: string[];
    dataPicker1: string | null;
    dataPicker2: string | null;
    dateRangeError: undefined | string;
    rangeAmount1: string;
    rangeAmount2: string;
    amountRangeError: undefined | string;
    reference: string;

    indexClosuresToBeMadeModal: IndexClosuresToBeMadeModal;
}

const initialState: ClosingState = {
    history: [],
    totalPages: -1,
    currentPage: pageInit,
    selectedBranch: "",
    selectedStatus: [], //""===null
    dataPicker1: null, //--bottom
    dataPicker2: null, //--top

    dateRangeError: undefined,

    rangeAmount1: "", //bottom
    rangeAmount2: "", //--top
    amountRangeError: undefined,

    reference: "",

    indexClosuresToBeMadeModal: IndexClosuresToBeMadeModal.none,
};

export const closingSlice = createSlice({
    name: "closing",
    initialState,
    reducers: {
        setHistory: (
            state,
            action: PayloadAction<{
                dataHistory: ClosingData[];
                totalPages: number;
            }>,
        ) => {
            state.history = action.payload.dataHistory;
            state.totalPages = action.payload.totalPages;
        },
        selectBranch: (state, action: PayloadAction<string>) => {
            state.selectedBranch = action.payload;
            state.currentPage = pageInit;
        },
        selectStatus: (state, action: PayloadAction<string>) => {
            state.selectedStatus = action.payload === "" ? [] : [action.payload];
            state.currentPage =pageInit;
        },
        setDataPicker: (state, action: PayloadAction<{ date1: string | null, date2: string | null }>) => {


            if (action.payload.date1 !== null && action.payload.date2 !== null) {

                const date1 = new Date(action.payload.date1);
                const date2 = new Date(action.payload.date2);

                if (date1 > date2) {
                    state.dateRangeError = "Por favor, asegúrese de ingresar una fecha final posterior a la fecha inicial";
                    return;
                }
                state.currentPage =pageInit;
            }

            state.dataPicker1 = action.payload.date1;
            state.dataPicker2 = action.payload.date2;

            state.dateRangeError = undefined;
        },
        setRangeAmount: (state, action: PayloadAction<{ amount1: string, amount2: string }>) => {

            if (action.payload.amount1 !== "" && action.payload.amount2 !== "") {

                if (isNaN(Number(action.payload.amount1))) {
                    state.amountRangeError = "Por favor, asegúrese de ingresar un monto inicial numérico";
                    return;
                }

                if (isNaN(Number(action.payload.amount2))) {
                    state.amountRangeError = "Por favor, asegúrese de ingresar un monto final numérico";
                    return;
                }

                if (Number(action.payload.amount1) > Number(action.payload.amount2)) {
                    state.amountRangeError = "Por favor, asegúrese de ingresar un monto final mayor al monto inicial";
                    return;
                }

                state.currentPage =pageInit;
            }


            state.rangeAmount1 = action.payload.amount1;
            state.rangeAmount2 = action.payload.amount2;

            state.amountRangeError = undefined;
        },
        setReference: (state, action: PayloadAction<string>) => {
            state.reference = action.payload;
            state.currentPage =pageInit;
        },

        clean: (state) => {
            (state.totalPages = -1);
            (state.currentPage = 1);
            (state.selectedBranch = "");
            (state.selectedStatus = []); //""===null
            (state.dataPicker1 = null); //--bottom
            (state.dataPicker2 = null); //--top
            (state.dateRangeError = undefined);
            (state.rangeAmount1 = ""); //bottom
            (state.rangeAmount2 = ""); //--top
            (state.amountRangeError = undefined);
            (state.reference = "");
        },
        openClosuresToBeMadeModal: (state, action: PayloadAction<IndexClosuresToBeMadeModal>) => {
            state.indexClosuresToBeMadeModal = action.payload;
        },
        closeClosuresToBeMadeModal: (state, action: PayloadAction<number>) => {
            state.indexClosuresToBeMadeModal = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        }
    },
});

export const {
    setHistory,
    selectBranch,
    selectStatus,
    setReference,
    clean,
    openClosuresToBeMadeModal,
    closeClosuresToBeMadeModal,
    setCurrentPage,
    setRangeAmount,
    setDataPicker
} = closingSlice.actions;

export default closingSlice.reducer;
