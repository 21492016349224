import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import drawerReducer from './slice/drawerSlice'
import currentUserReducer from './slice/currentUserSlice'
import {auth} from './api/service/auth'
import {passwordRecovery} from './api/service/passwordRecovery'
import messageReducer from './slice/messageSlice'
import {admin} from './api/service/admin'
import filterTransactionReducer from './slice/filterTransactionSlice'
import {transactionQueryAdmin} from "./api/service/transactionQueryAdmin";
import filterHistoryTransactionReducer from './slice/filterHistoryTransactionSlice';
import transactionModalReducer from  './slice/transactionModalSlice';
import {transaction} from "./api/service/transaction";
import {transactionQueryOperators} from "./api/service/transactionQueryOperators";
import {rtkQueryErrorLogger} from "./middleware/rtkQueryErrorLogger";
import {closing} from "./api/service/closing";
import closingReducer from './slice/closingSlice'
import filterClosingDetailReducer from "./slice/filterClosingDetailSlice"
import profileReducer from './slice/profileSlice';
import {user} from './api/service/user'
import filterUserReducer from './slice/filterUserSlice'
import userModalReducer from './slice/userModalSlice'
import assignableUsersReducer from "./slice/assinableUsersSlice";
import {closingDetail} from "./api/service/closingDetail";
import moneyWalletReducer from './slice/moneyWallet';
import {signup} from "./api/service/signup";

export const store = configureStore({
    reducer: {
        //Apis
        [auth.reducerPath]: auth.reducer,
        [passwordRecovery.reducerPath]: passwordRecovery.reducer,
        [admin.reducerPath]: admin.reducer,
        [transactionQueryAdmin.reducerPath]: transactionQueryAdmin.reducer,
        [transaction.reducerPath]: transaction.reducer,
        [transactionQueryOperators.reducerPath]: transactionQueryOperators.reducer,
        [closing.reducerPath]: closing.reducer,
        [user.reducerPath]: user.reducer,
        [closingDetail.reducerPath]:closingDetail.reducer,
        [signup.reducerPath]: signup.reducer,
        //slice
        close: closingReducer,
        filterClosingDetail: filterClosingDetailReducer,
        profile: profileReducer,
        drawer: drawerReducer,
        message: messageReducer,
        currentUser: currentUserReducer,
        filterTransaction: filterTransactionReducer,
        filterHistoryTransaction: filterHistoryTransactionReducer,
        transactionModal: transactionModalReducer,
        filterUser: filterUserReducer,
        userModal: userModalReducer,
        assignableUsers: assignableUsersReducer,
        moneyWallet: moneyWalletReducer
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(auth.middleware)
        .concat(passwordRecovery.middleware)
        .concat(admin.middleware)
        .concat(transactionQueryAdmin.middleware)
        .concat(transaction.middleware)
        .concat(transactionQueryOperators.middleware)
        .concat(closing.middleware)
        .concat(user.middleware)
        .concat(closingDetail.middleware)
        .concat(signup.middleware)
        .concat(rtkQueryErrorLogger)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
