import {KeyLocalStorage, TypeTransaction} from "./enum";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {Transaction} from "../../store/api/interface/transactionQueryAdmin";
import {VerifyUser} from "../../store/api/interface/transaction";
import {SessionInformation} from "../../interfaces/auth";
import {Information} from "../../interfaces/_models";
import {User, UserFilter} from "../../interfaces/user";
import {RootState} from "../../store/store";
import {ItemNotification} from "../../store/api/interface/admin";

export const getHeader = (headers: Headers, getState: () => unknown) => {
    const token = (getState() as RootState).currentUser.user.sessionId;
    if (!token) return headers;
    headers.set("Authorization", `Bearer ${token}`);
    return headers;
};

export const createSessionTime = (expires: number) => {
    const now = Date.now();
    const schedule = JSON.stringify(now + expires * 1000);
    return schedule; //get session time
};

export const getUserLocalStorage = () => {
    const storedExpiresIn = Number(localStorage.getItem(KeyLocalStorage.TIMEOUT));
    const expiresIn = isNaN(storedExpiresIn) ? 0 : Math.abs(storedExpiresIn);

    const data = localStorage.getItem(KeyLocalStorage.USER);

    if (data === null) return undefined; // no hay sesion

    if (expiresIn < Date.now()) {
        const userRemember = getLocalStorage(KeyLocalStorage.REMEMBERED);
        localStorage.clear();
        if(userRemember!==null){setLocalStorage(KeyLocalStorage.REMEMBERED,userRemember);}
        return null;
    }

    return data;
};

export const logOut = () => {
    const userRemember = getLocalStorage(KeyLocalStorage.REMEMBERED);
    localStorage.clear();
    if(userRemember!==null){setLocalStorage(KeyLocalStorage.REMEMBERED,userRemember);}
    document.location.reload();
};

export const getLocalStorage = (key: string): string | null => {
    return localStorage.getItem(key);
};

export const deleteLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};

export const setLocalStorage = (key: string, value: string) => {
    if (localStorage.getItem(key) !== null) deleteLocalStorage(key);
    localStorage.setItem(key, value);
};

export const initializeSessionInformation = (): SessionInformation => {
    return {
        data: undefined,
        sessionId: undefined,
    };
};

export const getBrowser = () => {
    const userAgent = (() => {
        const ua = navigator.userAgent;
        let tem;
        let M =
            ua.match(
                /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
            ) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return "IE " + (tem[1] || "");
        }
        if (M[1] === "Chrome") {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
        }
        M = M[2] ? [M[1], M[2]] : [navigator.userAgent, "-?"];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(" ");
    })().toString();

    const userAgentArray = userAgent;

    if (userAgentArray === null || userAgentArray === undefined) {
        return ["N/A", "N/A"];
    }

    if (userAgent.length < 2) {
        return ["N/A", "N/A"];
    }

    return [userAgent.split(" ")[0], userAgent.split(" ")[1]];
};

export const initializeInformation = (): Information => {
    return {
        browserName: getBrowser()[0],
        browserVersion: getBrowser()[1],
        osIanaTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        appLanguage: navigator.language,
        osTimezone: new Date().getTimezoneOffset() * 60,
        appVersion: "1.0.0",
    };
};

export const getRTKErrorMessage = (
    error: SerializedError | FetchBaseQueryError | undefined,
): string => {
    //console.log(error);

    if (error === undefined || error === null) {
        return "Ocurrió un error, intente nuevamente";
    }

    if (!("data" in error && error.data)) {
        return "Ocurrió un error, intente nuevamente"; //content data empity
    }

    const data = error.data as {
        info?: { type?: string; message?: string; title?: string };
    };

    //console.log(data);

    //debugger;
    return `${data.info?.title ? `${data.info.title}: ` : ""}${data.info
        ?.message}`; //en el ticket dice que hay que retornar el titulo mas el mensaje pero no todas las respuestas tienen tietulo y mensaje
};

export const getRtkErrorMessageUnstructured=(
    error: SerializedError | FetchBaseQueryError | undefined,
):{message:string, title:string} =>{

    if (error === undefined || error === null) {
        return {message:"Ocurrió un error, intente nuevamente", title:"Error"}
    }

    if (!("data" in error && error.data)) {
        return {message:"Ocurrió un error, intente nuevamente", title:"Error"}
    }

    const data = error.data as {
        info?: { type?: string; message?: string; title?: string };
    };

    return {message:data.info?.message||"Ocurrió un error, intente nuevamente", title:data.info?.title||"Ocurrió un error, intente nuevamente"}

}

export const formatDate = (originalDate: string, separator?: string): string => {
    if (!originalDate) return "";

    const dateObject = new Date(originalDate);
    if (isNaN(dateObject.getTime())) return "";

    const formattedDate = [
            ('0' + dateObject.getDate()).slice(-2),
            ('0' + (dateObject.getMonth() + 1)).slice(-2),
            dateObject.getFullYear()
        ].join('/') + ' ' +
        `${separator ? " " + separator + " " : " "}`
        +
        [
            ('0' + dateObject.getHours()).slice(-2),
            ('0' + dateObject.getMinutes()).slice(-2),
            ('0' + dateObject.getSeconds()).slice(-2)
        ].join(':');

    return formattedDate
};

export const formatDateWhitOutHour = (originalDate: string): string => {
    const date = new Date(originalDate);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
};

export const sortAscDate = (array: Transaction[]): Transaction[] => {
    return [...array].sort((a, b) => {
        const dateA = new Date(a.creationDate).getTime();
        const dateB = new Date(b.creationDate).getTime();
        return dateA - dateB;
    });
};

export const sortDescDate = (array: Transaction[]): Transaction[] => {
    return [...array].sort((a, b) => {
        const dateA = new Date(a.creationDate).getTime();
        const dateB = new Date(b.creationDate).getTime();
        return dateB - dateA;
    });
};

export const filterByTerms = (
    searchTerm: string,
    selectedStatus: string,
    selectedOperator: string,
    copyPendingTransactions: Transaction[],
    copyUnassignedTransactions: Transaction[],
    copyPaidTransactions: Transaction[],
    order: { type: TypeTransaction; value: "asc" | "desc" }[],
): {
    pending: Transaction[];
    unassigned: Transaction[];
    paid: Transaction[];
} => {
    let filterPendingTransactions = copyPendingTransactions;
    let filterUnassignedTransactions = copyUnassignedTransactions;
    let filterPaidTransactions = copyPaidTransactions;

    if (searchTerm !== "") {
        const filterByTerm = (item: Transaction) =>
            item.userTransaction?.name.includes(searchTerm) ||
            item.friUser.username.includes(searchTerm) ||
            item.friUser.name.includes(searchTerm) ||
            item.note?.includes(searchTerm) ||
            item.reference?.includes(searchTerm) ||
            item.amount.includes(searchTerm);

        filterPendingTransactions = copyPendingTransactions.filter(filterByTerm);
        filterUnassignedTransactions =
            copyUnassignedTransactions.filter(filterByTerm);
        filterPaidTransactions = copyPaidTransactions.filter(filterByTerm);
    }

    if (!(selectedStatus === "-1" || selectedStatus === "")) {
        const filterByStatus = (item: Transaction) =>
            item.status.includes(selectedStatus);
        filterUnassignedTransactions =
            filterUnassignedTransactions.filter(filterByStatus);
        filterPendingTransactions =
            filterPendingTransactions.filter(filterByStatus);
        filterPaidTransactions = filterPaidTransactions.filter(filterByStatus);
    }

    if (!(selectedOperator === "Todos" || selectedOperator === "")) {
        const filterByOperator = (item: Transaction) =>
            item.userTransaction?.username.includes(selectedOperator);

        filterUnassignedTransactions =
            filterUnassignedTransactions.filter(filterByOperator);
        filterPendingTransactions =
            filterPendingTransactions.filter(filterByOperator);
        filterPaidTransactions = filterPaidTransactions.filter(filterByOperator);
    }

    if (order[0].value === "asc") {
        filterPendingTransactions = sortAscDate(filterPendingTransactions);
    }

    if (order[1].value === "asc") {
        filterUnassignedTransactions = sortAscDate(filterUnassignedTransactions);
    }

    if (order[2].value === "asc") {
        filterPaidTransactions = sortAscDate(filterPaidTransactions);
    }

    return {
        pending: filterPendingTransactions,
        unassigned: filterUnassignedTransactions,
        paid: filterPaidTransactions,
    };
};

export const initVerifyUser = (): VerifyUser => {
    return {
        id: "",
        name: "",
        avatar: "",
        countryCode: "",
        phoneNumber: "",
        username: "",
    };
};

export const initializeTransaction = (): Transaction => {
    const transaction: Transaction = {
        id: 0, // Puedes asignar un valor adecuado
        businessId: "",
        transferId: "",
        origin: "",
        note: "",
        amount: "",
        formattedAmount: "",
        creationDate: "",
        formattedCreationDate: "",
        resolutionDate: "",
        status: "pending",
        friUser: {
            id: "",
            name: "",
            username: "",
            avatar: "",
            countryCode: "",
            phoneNumber: "",
            type: "",
        },
        applyForRefund: false,
        userTransaction: {
            id: "",
            name: "",
            username: "",
            emailAddress: "",
            creationDate: "",
            type: "",
            status: "",
        },
        reference: "",
    };

    return transaction;
};


export function convertDate(dateOriginal: string, type: 'end' | 'start'): string {
    const date = new Date(dateOriginal);

    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);

    if (type === 'end') {
        return `${year}-${month}-${day}T23:59:59.999Z`;
    } else {
        return `${year}-${month}-${day}T00:00:00.000Z`;
    }
}


export const getLink = (data: string) => {
    if (!data) return;
    const uint8Array = new Uint8Array(JSON.parse(data));
    const blob = new Blob([uint8Array], {type: "application/octet-stream"});
    const url = window.URL.createObjectURL(new Blob([blob]));
    return url;
};


export function parseFilename(inputString: string | null): string {
    const pattern = /filename\s*=\s*\\?"(.*?)"/;

    if (inputString === null) return "";
    const match = inputString.match(pattern);

    if (match) {
        return match[1];
    } else {
        return "";
    }
}


export function getHour(date: string): string {
    try {
        const dateHour = new Date(date);
        const hour = dateHour.getHours().toString().padStart(2, '0');
        const minutes = dateHour.getMinutes().toString().padStart(2, '0');
        return hour + ':' + minutes;
    } catch (error) {
        console.error("Error al convertir la cadena a fecha:", error);
        return "";
    }
}


export const downloadFile = (file: string, name: string) => {
    const url = getLink(file);
    if (!url) return;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
};
export const textIncludes = (text: string, textCompare: string) => {
    if (text && textCompare) {
        return text.toLowerCase().includes(textCompare.toLowerCase());
    }
    return false;
};

export const filterUsers = (users: User[], filter: UserFilter) => {
    if (!users) return [];
    return users.filter((entitie) => {
        if (entitie) {
            if (filter.status !== entitie.status && filter.status !== "all") {
                return false;
            }
            if (
                filter?.search &&
                !textIncludes(entitie.emailAddress, filter.search) &&
                !textIncludes(entitie.username, filter.search) &&
                !textIncludes(entitie.name, filter.search)
            ) {
                return false;
            }
        }
        return true;
    });
};

export function getIdMethodNotification(name:"push"|"webhook", list:ItemNotification[]) {
    const id = list.find(item => item.name === name);
    return id?id.id : -1;
}

export function getNameWebhookApiKey(id:string | undefined, list:ItemNotification[]) {
    if(id === undefined)return '';
    const idNumber= parseInt(id);
    const name = list.find(item => item.id === idNumber);
    return name?name.name : '';
}

export function getIdWebhookApiKey(name:string, list:ItemNotification[]) :string{
    const id = list.find(item => item.name === name);
    return id?id.id.toString() : '';
}



export function initializeEmptyUser(): User {
    return {
        id: '',
        name: '',
        username: '',
        emailAddress: '',
        creationDate: '',
        type: '',
        status: '',
        notificationMethod: '',
    };
}

export const formatDateBirthdaySelfService = (originalDate: string | undefined): string => {
    if(originalDate===undefined){
        return '';
    }

    if(originalDate===''){
        return ''
    }

    const date = new Date(originalDate);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    return `${year}-${month}-${day}`;
};