import {styled} from "@mui/material/styles";
import {Button, Typography} from "@mui/material";


export const ButtonCard = styled(Button)(({theme}) => ({
    borderRadius: "1.688rem",
    textTransform: "none",
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    height: "3.375rem",
    fontSize: "1.063rem",
    color: theme.palette.common.white,
    "&:disabled":{
        color: theme.palette.common.white
    },
    boxShadow: "none"
}));


export const TitleCardLoginWrap = styled(Typography)(({theme}) => ({
    textAlign: 'center',
    fontSize:"2.063rem"
}))

export const SubTextWrap = styled(Typography)(({theme}) => ({
    color: theme.palette.text.primary,
    fontSize: "1.125rem",
    "span":{
        color:theme.palette.primary.main
    },
    textAlign:'center',
    fontWeight: theme.typography.fontWeightLight
}));
