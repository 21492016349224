import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {cleanSuccessMessage} from "../../../../store/slice/messageSlice";
import {Collapse} from "@mui/material";
import {theme} from "../../../themes/theme";
import {AlertProps} from "../CustomAlert.types";
import {AlertSuccessStyle} from "./CustomAlertSuccess.styles";

const CustomAlertSuccess = ({text, show, title}: AlertProps) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (show === undefined) return;
        setOpen(show);
    }, [show]);

    const onClose = () => {
        setOpen(false);
        dispatch(cleanSuccessMessage());
    };

    return (
        <Collapse in={open}>
            <AlertSuccessStyle onClose={onClose} icon={false} >
                {title && <span style={{fontWeight: theme.typography.fontWeightMedium}}>{`${title}: `}</span>}
                {text}
            </AlertSuccessStyle>
        </Collapse>
    );
};

export default CustomAlertSuccess