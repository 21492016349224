export enum KeyLocalStorage {
    USER = 'user',
    TIMEOUT = 'timeOut',
    TIMEOUTVALUE = 21600,
    REMEMBERED='rememberedUser'
}

export enum FormErrorMessages{
    RequiredField ='Este campo es requerido',
    InvalidEmail = 'El correo electrónico no es válido'
}

export enum Rol{
    ADMIN='admin',
    OPERATOR='operator'
}

export enum TransactionColor{
    info='info',
    success='success',
    danger='danger'
}

export const TransactionStatusColor = {
    pending: "danger",
    completed: "success",
    rejected: "danger",
    canceled: "danger",
    unassigned: "info",
    refunded: "info",
    expired: "danger",
};

export enum TypeTransaction{
    pending,
    paid,
    unassigned
}
//UI
export const transactionStatus = {
    completed: "Recibido",
    pending: "Pendiente",
    rejected: "Rechazado",
    canceled: "Anulado",
    unassigned: "Sin asignar",
    refunded: "Reembolsado",
    expired: "Expirado",
};

export const pageSize = 10;

//"processing" | "completed" | "canceled"
export const closingStatus ={
    processing:"Procesando",
    error:"Error",
    canceled:"Cancelado",
    completed:"Completado"
}

export const closingStatusColor = {
    processing:"info",
    error:"danger",
    canceled:"danger",
    completed:"success"
}

//"completed" | "canceled" | "rejected" |"refunded"
export const transactionClosingStatus={
    completed: "Completado",
    rejected: "Rechazado",
    canceled: "Cancelado",
    refunded: "Rembolsado",
}

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const taxRegex = /^[cC0-9][a-zA-Z0-9]*$/

export const numberRegex = /^[0-9]+$/

export const statusTransaction = [
    {value: "-1", text: "Todos"},
    {value: "rejected", text: "Rechazado"},
    {value: "canceled", text: "Anulado"},
    {value: "refunded", text: "Reembolsado"},
    {value: "unassigned", text: "Sin asignar"},
    {value: "pending", text: "Pendiente"},
];

export const statusHistoryTransaction =   [
    { value: "pending", text: "Pendiente" },
    { value: "completed", text: "Recibido" },
    { value: "rejected", text: "Rechazado" },
    { value: "canceled", text: "Anulado" },
    { value: "unassigned", text: "Sin asignar" },
    { value: "refunded", text: "Reembolsado" },
    { value: "expired", text: "Expirado" },
];

export const statusClosingDetail= [ //"completed" | "canceled" | "rejected" |"refunded"
    {value: "completed", text: "Completado"},
    {value: "canceled", text: "Cancelado"},
    {value: "rejected", text: "Rechazado"},
    {value: "refunded", text: "Reembolsado"},
];

export const statusClosing= [
    { value: "processing", text: "Procesando" },
    { value: "completed", text: "Completado" },
    { value: "canceled", text: "Cancelado" },
    { value: "error", text: "Error" },
]


export const formatDate = "DD/MM/YYYY";

export const pageInit = 1;

export enum TypeBusinessAffiliations{
    formal=1,
    noFormal=2,
    none=0
}

export const siteKey = "6LdKpskfAAAAAIGNqe2IV9JA4OK_WZ-80enWi-ho";