import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Transaction} from "../api/interface/transactionQueryAdmin";
import {filterByTerms} from "../../_fri/utils/function";
import {TypeTransaction} from "../../_fri/utils/enum";


export interface FilterTransactionState {
    selectedStatus: string;
    selectedOperator: string
    filterUnassignedTransactions: Transaction[],
    filterPendingTransactions: Transaction[],
    filterPaidTransactions: Transaction[],
    copyUnassignedTransactions: Transaction[],
    copyPendingTransactions: Transaction[],
    copyPaidTransactions: Transaction[],
    searchTerm: string
    order: { type: TypeTransaction, value: "asc" | "desc" } []
}

const initialState: FilterTransactionState = {
    selectedStatus: "",
    selectedOperator: "",
    filterUnassignedTransactions: [],
    filterPendingTransactions: [],
    filterPaidTransactions: [],
    copyUnassignedTransactions: [],
    copyPendingTransactions: [],
    copyPaidTransactions: [],
    searchTerm: "",
    order: [
        {type: TypeTransaction.pending, value: "desc"},
        {
            type: TypeTransaction.unassigned,
            value: "desc",
        },
        {type: TypeTransaction.paid, value: "desc"},
    ],
};

export const filterTransactionSlice = createSlice({
    name: "filterTransaction",
    initialState,
    reducers: {
        selectStatus: (state, action: PayloadAction<string>) => {
            state.selectedStatus = action.payload;
        },
        selectOperator: (state, action: PayloadAction<string>) => {
            state.selectedOperator = action.payload;
        },
        setUnassignedTransaction: (state, action: PayloadAction<Transaction[]>) => {
            state.copyUnassignedTransactions = action.payload;
        },
        setPendingTransaction: (state, action: PayloadAction<Transaction[]>) => {
            state.copyPendingTransactions = action.payload;
        },
        setPaidTransaction: (state, action: PayloadAction<Transaction[]>) => {
            state.copyPaidTransactions = action.payload;
        },
        setSearchTerm: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload;
        }
        ,
        filter: (state) => {

            const {
                searchTerm,
                selectedStatus,
                selectedOperator,
                copyUnassignedTransactions,
                copyPendingTransactions,
                copyPaidTransactions,
                order
            } = state;

            const {pending, unassigned, paid} = filterByTerms(
                searchTerm,
                selectedStatus,
                selectedOperator,
                copyPendingTransactions,
                copyUnassignedTransactions,
                copyPaidTransactions,
                order);

            state.filterPaidTransactions = paid;
            state.filterUnassignedTransactions = unassigned;
            state.filterPendingTransactions = pending;

        },
        sortAsc: (state, action: PayloadAction<TypeTransaction>) => {

            if (action.payload === TypeTransaction.pending) {
                state.order[0].value = "asc";
                return;
            }

            if (action.payload === TypeTransaction.unassigned) {
                state.order[1].value = "asc";
                return;
            }

            if (action.payload === TypeTransaction.paid) {
                state.order[2].value = "asc";
                return;
            }

        },

        sortDesc: (state, action: PayloadAction<TypeTransaction>) => {

            if (action.payload === TypeTransaction.pending) {
                state.order[0].value = "desc";
                return;
            }

            if (action.payload === TypeTransaction.unassigned) {
                state.order[1].value = "desc";
                return;
            }

            if (action.payload === TypeTransaction.paid) {
                state.order[2].value = "desc";
                return;
            }
        },
        clean: (state) => {
            state.selectedStatus = "";
            state.selectedOperator = "";
            state.searchTerm = "";
            state.order = [
                {type: TypeTransaction.pending, value: "desc"},
                {
                    type: TypeTransaction.unassigned,
                    value: "desc",
                },
                {type: TypeTransaction.paid, value: "desc"},
            ];

        }
    }
});

export const {
    selectStatus,
    selectOperator,
    setUnassignedTransaction,
    setPendingTransaction,
    setPaidTransaction,
    filter,
    setSearchTerm,
    sortAsc,
    sortDesc,
    clean,
} = filterTransactionSlice.actions;
export default filterTransactionSlice.reducer;
