import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {getHeader, parseFilename} from "../../../_fri/utils/function";
import {
    BalanceAdminRequest,
    BalanceAdminResponse,
    BalanceOperatorRequest,
    BalanceOperatorResponse,
    DownloadHistoryExcelTransaction,
    HistoryRequest,
    HistoryResponse,
    VerifyUserRequest,
    VerifyUserResponse,
} from "../interface/transaction";
import {setUserNewRequestModal} from "../../slice/transactionModalSlice";
import {setTotalPages} from "../../slice/filterHistoryTransactionSlice";
import {setMoney} from "../../slice/moneyWallet";

export const transaction = createApi({
    reducerPath: "transaction",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/business/transactions/`,
        prepareHeaders: (headers, {getState}) => {
            return getHeader(headers, getState);
        },
    }),
    endpoints: (builder) => ({
        verifyUser: builder.mutation<VerifyUserResponse, VerifyUserRequest>({
            query: (data) => ({
                url: `v2/verify-user`,
                method: "Post",
                body: data,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch((e) => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(setUserNewRequestModal(result.data.responseContent));
            },
        }),
        history: builder.query<HistoryResponse, HistoryRequest>({
            query: (data) => ({
                url: `v2/history`,
                method: "Post",
                body: data,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled.catch((e) => ({error: e}));
                if ("error" in result) {
                    return;
                }
                dispatch(
                    setTotalPages(result.data.responseContent.pageProperties.totalPages),
                );
            },
        }),
        downloadHistoryExcel: builder.mutation<
            { file: string; name: string },
            DownloadHistoryExcelTransaction
        >({
            query: (requestData) => ({
                url: `v2/history/excel`,
                method: "POST",
                responseType: "arrayBuffer",
                body: requestData,
                responseHandler: async (response) => {
                    const buffer = await response.arrayBuffer();
                    const uint8Array = new Uint8Array(buffer);
                    const serializedArray = JSON.stringify(Array.from(uint8Array));
                    const name = response.headers.get("Content-Disposition");
                    return {file: serializedArray, name: parseFilename(name)};
                },
            }),
        }),
    }),
});

export const {
    useVerifyUserMutation,
    useHistoryQuery,
    useDownloadHistoryExcelMutation,
} = transaction;
