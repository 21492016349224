import {AlertProps} from "../CustomAlert.types";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {cleanErrorMessage} from "../../../../store/slice/messageSlice";
import {Collapse} from "@mui/material";
import {AlertDangerStyle} from "./CustomAlertDanger.styles";
const CustomAlertDanger = ({text, show,sx}: AlertProps) => {

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (show === undefined) return;
        setOpen(show);
    }, [show]);

    const onClose = () => {
        setOpen(false);
        dispatch(cleanErrorMessage());
    };

    return (
        <Collapse in={open}>
            <AlertDangerStyle sx={sx} onClose={onClose} icon={false}>{text}</AlertDangerStyle>
        </Collapse>
    );
};

export default CustomAlertDanger