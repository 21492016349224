import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserFilter} from '../../interfaces/user'

const initialUserFilter: UserFilter = {
    search: '',
    status: 'all',
}

const userFilterSlice = createSlice({
    name: 'userFilter',
    initialState: initialUserFilter,
    reducers: {
        setFilter: (state, action: PayloadAction<UserFilter>) => {
            state.search = action.payload.search
            state.status = action.payload.status
        },
        cleanFilterUser:(state)=>{
            state.search=''
            state.status='all'
        }
    },
})

export const {setFilter, cleanFilterUser} = userFilterSlice.actions

export default userFilterSlice.reducer
