import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {VerifyUser} from "../api/interface/transaction";
import {initializeTransaction, initVerifyUser} from "../../_fri/utils/function";
import {Transaction} from "../api/interface/transactionQueryAdmin";

export enum IndexTransactionModal{
    none=0,
    searchUser=1,
    newRequest=2,
    cancel=3,
    refund=4,
    assign=5,
    reject =6

}
export interface TransactionModalState {
    indexTransactionModal: IndexTransactionModal; //is the modal
    user: VerifyUser;
    transaction: Transaction
}

const initialState: TransactionModalState = {
    indexTransactionModal: 0,
    user: initVerifyUser(),
    transaction: initializeTransaction(),
};

export const transactionModalSlice = createSlice({
    name: "transactionModal",
    initialState,
    reducers: {
        setIndexTransactionModal: (state, action: PayloadAction<{ index:IndexTransactionModal, transaction?:Transaction }>) => {
            state.indexTransactionModal = action.payload.index;
            if(action.payload.transaction===undefined)return;
            state.transaction= action.payload.transaction;
        },
        setUserNewRequestModal:(state, action: PayloadAction<VerifyUser>)=>{
            state.user= action.payload;
        },
    }
});

export const {
    setIndexTransactionModal, setUserNewRequestModal
} = transactionModalSlice.actions;

export default transactionModalSlice.reducer;