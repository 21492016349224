import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "../_fri/layout/main-layout/MainLayout";
import {LinearProgress} from "@mui/material";
import useRolHook from "../_fri/hooks/useRolHook";
import {Rol} from "../_fri/utils/enum";
const ClosingPage = React.lazy(()=>import('../modules/closing/ClosingPage'));
const TransactionPage = React.lazy(()=>import('../modules/transaction/TransactionPage'));
const UserPage = React.lazy(()=>import('../modules/user/UserPage'));
const ProfilePage = React.lazy(()=> import('../modules/profile/ProfilePage'))
export const PrivateRoute = () => {
  const rol = useRolHook();
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path="auth/*"
          element={<Navigate to="/transaction/dashboard" />}
        />

        <Route path="transaction/*" element={<React.Suspense fallback={ <LinearProgress />}> <TransactionPage /></React.Suspense>} />

        <Route path="closing/*" element={<React.Suspense fallback={ <LinearProgress />}><ClosingPage /></React.Suspense>} />

        {rol === Rol.ADMIN &&<Route path="user/*" element={<React.Suspense fallback={<LinearProgress />}><UserPage /></React.Suspense>} />}

        <Route path="profile/*"  element={<React.Suspense fallback={<LinearProgress />}><ProfilePage /></React.Suspense>} />

        <Route path="*" element={<Navigate to="/"/>}/>
      </Route>
    </Routes>
  );
};
