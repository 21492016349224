import {User} from "../api/interface/admin";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface AssignableUsers{
    assignableUsers:User[]
}

const initialState:AssignableUsers={
    assignableUsers:[]
}

export const assignableUsers = createSlice({
    name:"assignableUsers", /*slice para las sucursales*/
    initialState,
    reducers:{
        setAssignableUsers: (state, action: PayloadAction<User[]>) => {
            state.assignableUsers = action.payload;
        },
    }
})

export const {
    setAssignableUsers
} = assignableUsers.actions

export default assignableUsers.reducer;